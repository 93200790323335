<template lang="pug">
input-field(
  v-model="formData.zipCode"
  type="text"
  name="zip_code"
  :label="$t('.label.zipCode')"
  :placeholder="$t('.placeholder.zipCode')"
  v-maska="'#####-###'"
  :error="errors.zipCode"
  @input="getAddress"
  required
)

input-field(
  v-model="formData.state"
  type="text"
  name="state"
  :label="$t('.label.state')"
  :placeholder="$t('.placeholder.state')"
  :error="errors.state"
  required
)

input-field(
  v-model="formData.city"
  type="text"
  name="city"
  :label="$t('.label.city')"
  :placeholder="$t('.placeholder.city')"
  :error="errors.city"
  required
)

input-field(
  v-model="formData.neighborhood"
  type="text"
  name="neighborhood"
  :label="$t('.label.neighborhood')"
  :placeholder="$t('.placeholder.neighborhood')"
  :error="errors.neighborhood"
  required
)

input-field(
  v-model="formData.street"
  type="text"
  name="street"
  :label="$t('.label.street')"
  :placeholder="$t('.placeholder.street')"
  :error="errors.street"
  required
)

input-field(
  v-model="formData.number"
  type="number"
  name="number"
  :label="$t('.label.number')"
  :placeholder="$t('.placeholder.number')"
  :error="errors.number"
  required
)
</template>

<script>
import cep from 'cep-promise'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgencies.createModal.location"
    }
  },

  props: {
    formData: {type: Object, default: () => ({}) },
    errors: { type: Object, default: () => ({}) }
  },

  methods: {
    getAddress(value) {
      let cepValue = value.target.value.replace('-', '')

      if (cepValue.length < 8) return

      cep(cepValue).then((result) => {
        Object.assign(
          this.formData,
          {
            state: result.state,
            neighborhood: result.neighborhood,
            city: result.city,
            street: result.street,
            cep: `${result.cep.slice(0, 5)}-${result.cep.slice(5)}`
          }
        )
      })
    }
  }
}
</script>
