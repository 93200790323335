<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal 
    .info-container
      h1 {{ $t('.title') }}
      p {{ $t('.description') }}

    .buttons-container
      button.close(@click="close") {{ $t(".buttons.close") }}

      //- TODO Agreement redirection
      //- button.redirect(@click="redirect") 
      //-   | {{ $t(".buttons.redirect") }}
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    show: { type: Boolean, default: false },
    agreementId: { type: Number, require: true }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      defaultI18nScope: "admin.bills.parentAgreementRedirectionModal",
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

//  TODO Agreement redirection
//     redirect() {
//       this.$router.push({
//         path: `/administracao/financeiro/acordos/${this.agreementId}`
//       })
//     }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 90%;

  @media (orientation: landscape) {
    width: 60%;
  }

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end; 
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .redirect {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>


<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>