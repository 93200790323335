<template lang="pug">
select-field(
  :name="name",
  :options="options",
  :includeBlank="true",
  :error="error",
  :label="$t('.label')"
  @input="(val) => { $emit('update:modelValue', val) }"
  :multiple="true"
)
</template>

<script>
  export default {
    data() {
      return {
        // i18n
        defaultI18nScope: "admin.contracts.statusField"
      }
    },

    computed: {
      options() {
        return [
          { id: 'active', text: this.translateStatus('active') },
          { id: 'inactive', text: this.translateStatus('inactive') },
          { id: 'to_be_vacant', text: this.translateStatus('to_be_vacant') },
          { id: 'eviction_process', text: this.translateStatus('eviction_process') },
          { id: 'paused', text: this.translateStatus('paused') },
          { id: 'under_verification', text: this.translateStatus('under_verification') }
        ]
      }
    },

    methods: {
      translateStatus(status) {
        return this.$t(`models.contract.status.${status}`)
      }
    },

    props: {
      name: { type: String },
      error: { type: String }
    }
  }
</script>
