<template lang="pug">
.dropdown
  button.dropdown-toggle(@click="isOpen = !isOpen", :class="{ 'open': isOpen }")
    span(v-if="!!title")
      | {{ title }}
    i.fa(v-if="!!icon" :class="icon")

    i.fa.fa-chevron-down(v-if="!isOpen")
    i.fa.fa-chevron-up(v-else)

  ul.dropdown-menu(v-if="urls.length > 0", :class="{ 'open': isOpen }, orientation")
    a(v-for="(url, index) in urls", :href="url.address", target="_blank")
      | {{ url.name }}

  ul.dropdown-menu(v-else-if="callbacks.length > 0", :class="{ 'open': isOpen }, orientation")
    button(
      v-for="(callback, index) in callbacks",
      @click="callback.callback"
    )
      | {{ callback.name }}
</template>

<script>
export default {
  props: {
    urls: { type: Array, default: () => [] }, // [{ name: 'itemLabel', address: 'http://example.com' }]
    callbacks: { type: Array, default: () => [] }, // [{ name: 'itemLabel', callback: () => {} }]
    title: { type: String, required: true },
    icon: { type: String, required: true },
    orientation: { type: String, default: 'right' }
  },

  data() {
    return {
      isOpen: false
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: block;

  button {
    margin-bottom: 0;
  }

  .dropdown-toggle {
    color: $blue-color;
    background-color: lighten($blue-color, 36%);
    font-weight: 600;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-transform: none;

    &:hover {
      background-color: lighten($blue-color, 31%);
    }

    &.open {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    i {
      margin-top: 0;
      margin-left: 0.7rem;
      font-size: 1.5rem;
    }
  }

  ul {
    list-style: none;

    &.dropdown-menu {
      display: none;
      position: absolute;
      border-radius: 0 0 0.5rem 0.5rem;
      background-color: lighten($blue-color, 36%);
      box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);
      padding: 0.8rem;

      &.left {
        left: auto;
        right: 0px;
      }

      &.right {
        left: 0px;
        right: auto;
      }

      &.open {
        display: grid;

        z-index: 15;
      }
    }

    a, button {
      font-family: "Open Sans", Arial, sans-serif;
      text-transform: initial;
      text-decoration: none;
      color: $blue-color;
      font-weight: 600;
      font-size: 1.5rem;
      text-align: start;

      padding: 1rem 0.5rem;
      border: none;
      height: min-content;
      line-height: 2.5rem;
      letter-spacing: .1rem;

      cursor: pointer;

      &:hover {
        background-color: lighten($blue-color, 31%);
        border-radius: 0.5rem;
      }
    }
  }
}
</style>