<template lang="pug">
Modal(v-model="isShow" :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}

      form(id="edit-bill-service-form" ref="form" @submit.prevent="submit")
        input-field(
          name="bill_service[description]"
          :label="$t('.label.description')"
          :placeholder="$t('.placeholder.description')"
          :disabled="true"
          v-model="description"
        )

        service-nature-field(
          name="bill_service[service_nature]"
          :required="true"
          :error="errors.serviceNature"
          v-model="serviceNature"
        )

        transfer-type-field(
          name="bill_service[default_transfer_type]"
          :required="true"
          :error="errors.defaultTransferType"
          v-model="defaultTransferType"
        )

        checkbox-field(
          name="bill_service[system_service]"
          :label="$t('.label.systemService')"
          :error="errors.systemService"
          @update:checked="check => systemService = check"
        )

      .buttons-container
        button.close(
          :disabled="submitting"
          @click="close"
        )
          | {{ $t(".buttons.close") }}

        button.confirm(
          form="edit-bill-service-form"
          type="submit"
          :class="submitting && 'button-progress'"
          :disabled="submitting || requiredAreBlank"
        )
          | {{ $t(".buttons.confirm") }}
</template>
<script>
import { ref } from 'vue'

import ServiceNatureField from './service-nature-field.vue'
import TransferTypeField from './default-transfer-type-field.vue'

export default {
  components: { ServiceNatureField, TransferTypeField },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.billServices.editModal",

      submitting: false,
      errors: {},

      // form
      description: null,
      serviceNature: null,
      defaultTransferType: null,
      systemService: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      this.clearData()

      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    contractId: { type: Number, default: null },
    billService: { type: Object, default: () => ({}) },
    callback: { type: Function, default: () => {} }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    disableDimmedClick() {
      this.options['closeClickDimmed'] = false
    },

    submit() {
      this.submitting = true

      this.$http
        .put(`/admins/bill-services/${this.billService.id}`, this.submitParams)
        .then(() => {
          this.$notifications.info(this.$t(".notifications.success"))

          this.callback()

          this.close()
        })
        .catch((err) => {
          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.$notifications.error(this.$t(".notifications.failure"))

          this.submitting = false
        })
    },

    clearData() {
      this.description = this.billService.description || null
      this.serviceNature = this.billService.serviceNature || null
      this.defaultTransferType = this.billService.defaultTransferType || null
      this.systemService = this.billService.systemService || false
      this.submitting = false
    }
  },

  computed: {
    requiredAreBlank() {
      return !this.serviceNature && this.defaultTransferType
    },

    submitParams() {
      return {
        bill_service: {
          description: this.description,
          service_nature: this.serviceNature,
          default_transfer_type: this.defaultTransferType,
          system_service: this.systemService
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  width: 50%;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
