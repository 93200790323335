<template lang="pug">
.editable-text-area-field
  p.info-content(
    v-if="editingDisabled",
    @click="editingDisabled = false"
  )
    | {{ getValue }}

  .edit-container(v-else)
    form(ref="form", action="/", @submit.prevent="submit")
      textarea(
        :name="name",
        :value="getValue",
        :type="type"
      )

      .buttons
        button.button-primary(
          type="submit",
          :disabled="submitting"
        )
          i.fa.fa-check

        button.button-primary.close(
          :disabled="submitting",
          @click="editingDisabled = true"
        )
          i.fa.fa-times
</template>

<script>
import InputField from "../input-field.vue"

export default {
  extends: InputField,

  watch: {
    value(value) {
      this.inputValue = value
    }
  },

  props: {
    name: { type: String, required: true },
    value: { type: [String, Number] },
    type: { type: String, default: "text" },
    path: { type: String, required: true }
  },

  data() {
    return {
      defaultI18nScope: "components.editableTextAreaField",

      submitting: false,
      editingDisabled: true,
      inputValue: this.value
    }
  },

  methods: {
    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      formData.set(this.name, formData.get(this.name))

      this.$http
        .put(this.path, formData)
        .then(() => {
          this.inputValue = formData.get(this.name)

          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .then(() => {
          this.submitting = false
          this.editingDisabled = true
        })
    }
  },

  computed: {
    getValue() {
      return this.inputValue || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.editable-text-area-field  {
  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    textarea {
      width: 100%;
      padding: 10px 10px;
      font-family: Lato;
      font-size: 1.5rem;

      resize: none;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }
    }
  }

  p {
    &.info-content {
      padding: 0px 0px;
      font-size: 1.5rem;
      font-family: Lato;

      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }
}
</style>