<template lang="pug">
select
  slot(name="blank", v-if="includeBlank")
    option(value="", selected, disabled="true") {{ $t('options.blank') }}
  slot
</template>

<script>
const SCHEDULE_TIMEOUT = 50;

export default {
  props: {
    includeBlank: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
    modal: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    overlay: { type: Boolean, default: false },
    type: { type: String, default: "string" },
    value: { type: [String, Number], default: "" },
    placeholder: { type: String, default() { return '' } },
    url: { type: String, required: false },
    dataFormatting: { type: Function, required: false },
    processResults: { type: Function, required: false },
    multiple: { type: Boolean, default: false }
  },

  data() {
    return {
      initialized: false,
      select2: null,
    };
  },

  computed: {
    select2Options() {
      let options = {
        data: this.options,
        placeholder: this.placeholder,
        ajax: this.apiConnection(),
        multiple: this.multiple ? 'multiple': ''
      };

      if (this.hideSearch) {
        options.minimumResultsForSearch = Infinity;
      }

      if (this.modal) {
        options.dropdownParent = this.$el.closest(".modal-container");
      } else if (this.overlay) {
        options.dropdownParent = this.$el.closest(".overlay");
      }

      return options;
    },
  },

  mounted() {
    this.select2 = $(this.$el)
      .select2(this.select2Options)
      .on("select2:select select2:unselect", (evt) => {
        this.multiple ? this.multipleReturn(evt) : this.singleReturn(evt)
      });

    if (this.value) this.setVal(this.value);

    this.initialized = true;
  },

  beforeUnmount() {
    if (this.select2) this.select2.off().select2("destroy");
  },

  methods: {
    apiConnection() {
      if (!this.url || !this.dataFormatting || !this.processResults) return

      return {
        data: this.dataFormatting,
        transport: (params, success) => {
          this.$http.get(this.url, { params: params.data })
            .then((response) => {
              success(response)
            }).catch(() => {
              this.$notifications.error(this.$t(".notifications.fetch.failure"))
            })
        },
        processResults: this.processResults
      }
    },

    singleReturn(evt) {
      const { id, text } = evt.params.data;

      this.$emit("input", this.castVal(id), this.castVal(text));
    },

    multipleReturn(evt) {
      const texts = []
      const ids = []

      $(evt.target).select2('data').forEach((item) => {
        texts.push(this.castVal(item.text))
        ids.push(this.castVal(item.id))
      })

      this.$emit("input", ids, texts);
    },

    castVal(val) {
      if (val == null) return null;

      switch (this.type) {
        case "integer": {
          return parseInt(val, 10)
        }
        case "float": {
          return parseFloat(val)
        }
        case "string":
        default: { return val }
      }
    },

    setVal(val) {
      this.select2.val(val).trigger("change");
    },

    schedule(fn) {
      if (this.initialized) {
        fn();
        return;
      }

      let fnSchedule = () => {
        if (this.initialized) {
          fn();
        } else {
          setTimeout(fnSchedule, SCHEDULE_TIMEOUT);
        }
      };

      setTimeout(fnSchedule, SCHEDULE_TIMEOUT);
    },
  },

  watch: {
    value(newValue) {
      this.schedule(() => {
        this.setVal(newValue);
      });
    },
    options() {
      this.schedule(() => {
        if (this.includeBlank && this.value == null) {
          this.select2.select2(this.select2Options).val(this.value);
        } else {
          this.select2.empty().select2(this.select2Options).val(this.value);
        }
      });
    },
    placeholder() {
      this.schedule(() => {
        this.select2.select2(this.select2Options);
      });
    },
  },
};
</script>

<style lang="scss">
.select2-selection {
  outline: none;
}

.select2-selection--multiple {
  border: none !important;
  padding: 0;
  background-color: transparent !important;

  .select2-selection__rendered {
    display: flex !important;
    height: 100%;
    width: 100% !important;
    align-items: center;
    overflow: scroll !important;

    .select2-selection__choice {
      font-family: 'Raleway';
      font-size: 1.2rem;
      color: $white-color;
      background-color: $primary-color !important;
      border: none;
      padding: 0 1rem;
      height: 98%;
      margin: 0 0.5rem 0 0 !important;

      span {
        color: $white-color !important;
      }
    }
  }
}

.select2-container {
  border: 1px solid #d1d1d1 !important;
}

.select2-selection__placeholder {
  color: #cccccc !important;
  font-style: italic;
}
</style>
