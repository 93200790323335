<template lang="pug">
.score-reports-index
  .score-reports-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

          button.icon.button-blue(@click="showNewModal = true")
            i.fa.fa-plus

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        manager-field(
          v-if="$user.isRealEstateAdmin"
          v-model="query.realEstateManager"
          name="real_estate_manager"
          :label="$t('.label.realEstateManager')"
          :placeholder="$t('.placeholder.realEstateManager')"
        )

        attendant-field(
          v-if="!$user.isRealEstateAttendant"
          v-model="query.realEstateAttendant"
          name="real_estate_attendant"
          :label="$t('.label.realEstateAttendant')"
          :placeholder="$t('.placeholder.realEstateAttendant')"
        )

        input-field(
          v-model="query.name"
          name="name"
          :label="$t('.label.name')"
          :placeholder="$t('.placeholder.name')"
        )

        input-field(
          v-model="query.cpf"
          name="cpf"
          :label="$t('.label.cpf')"
          :placeholder="$t('.placeholder.cpf')"
          mask="###.###.###-##"
        )

    .no-data-container(v-if="!loading && scoreReports.length == 0")
      img.image(src="@/assets/images/public/no-data.svg")

      h2 {{ $t('.pagination.zero') }}

    .cards-container
      loadingCard(v-if="loading" v-for="card in 6")

      score-report-card(
        v-for="report in scoreReports"
        :key="report.id"
        :scoreReport="report"
        :fetchCallback="fetch.bind(this)"
        :url="$user.prefixUrlAgency"
        showPath="gerenciamento/consultas"
      )

  new-modal(
    :show="showNewModal"
    @close="showNewModal = false"
    @submitted="fetch"
  )
</template>
<script>
import FilterContainer from "../../../components/filter-container.vue"
import ScoreReportCard from "./card.vue"
import LoadingCard from "../../../components/loading-card.vue"
import ManagerField from "../manager-field.vue"
import AttendantField from "../attendant-field.vue"
import NewModal from "./new-modal.vue"

import objects from "lib/objects"

export default {
  components: {
    ManagerField, AttendantField, FilterContainer, ScoreReportCard, LoadingCard,
    NewModal
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.scoreReport.index",

      // data
      scoreReports: [],
      pagination: { total: 0 },
      loading: false,

      // filter
      filterOpened: false,
      query: {
        realEstateManager: null,
        realEstateAttendant: null,
        name: null,
        cpf: null
      },

      // modal
      showNewModal: false
    }
  },

  props: {
    shouldFetch: { type: Boolean, default: false }
  },

  watch: {
    shouldFetch(value) {
      if (value && !this.loading && this.pagination.next) {
        this.fetch(this.pagination.next)
      }
    }
  },

  computed: {
    paginationText() {
      if (this.loading) return this.$t(".pagination.loading")

      return this.$t(".pagination.text", {
        length: this.scoreReports.length,
        total: this.pagination.total
      })
    }
  },

  methods: {
    init(scoreReports, pagination) {
      this.scoreReports.push(...objects.camelize(scoreReports))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total")
      }
    },

    filter() {
      this.replaceQuery()

      this.scoreReports = []

      this.fetch()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      if (page === null) this.scoreReports = []

      this.$http
        .get(
          `/${this.$user.prefixUrlAgency}/score-reports.json`,
          { params: this.httpParams(page) }
        )
        .then((response) => {
          const body = response.data
          const scoreReports = body.data
          const pagination = body.pagination || {}

          this.init(scoreReports, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    httpParams(page = null) {
      const params = { name: this.query.name, cpf: this.query.cpf, page: page }

      if (this.$user.isRealEstateManager) {
        params.real_estate_user = this.query.realEstateAttendant
      }

      if (this.$user.isRealEstateAdmin) {
        params.real_estate_user = this.query.realEstateManager || this.query.realEstateAttendant
      }

      return this.$params.toRouteParams(params)
    }
  }
}
</script>
<style lang="scss" scoped>
.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.counter-container {
  margin-bottom: 2rem;

  .counter {
    color: $grey-color-dark;
    font-style: italic;
  }
}

.no-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  img {
    width: 31rem;
    margin-bottom: 1rem;
  }

  h2 {
    color: $primary-color;
    font-size: 3rem;
    font-weight: 400;
  }
}

.cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  margin-bottom: 3rem;

  @media only screen and (max-width: 930px) {
    grid-template-columns: 1fr;
  }
}

</style>
