<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .info-container
      h1 {{ $t('.title') }}

      form(ref="form", action="/")
        contract-field(
          v-if="!contractId"
          v-model="contract",
          name="transfer[contract_id]",
          :required="true",
          :error="errors.contract"
        )

        date-field(
          v-model="dueDate",
          name="transfer[due_date]",
          :label="$t('.label.dueDate')",
          :required="true",
          :error="errors.dueDate"
        )

      .buttons-container
        button.close(
          :disabled="submitting || (!contractId && !contract) || !dueDate",
          @click="close"
        )
          | {{ $t(".buttons.close") }}
        button.confirm(
          :disabled="submitting",
          @click="submit"
        )
          | {{ $t(".buttons.confirm") }}
</template>

<script>
import objects from "lib/objects"
import { ref } from 'vue'

import ContractField from '../contract-field.vue'

export default {
  components: { ContractField },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.transfers.modal",

      submitting: false,
      errors: {},

      // form
      dueDate: null,
      contract: null
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    contractId: { type: Number, default: null }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    close() {
      this.$emit('close')
    },

    disableDimmedClick() {
      this.options['closeClickDimmed'] = false
    },

    formData() {
      return {
        contract_id: this.contractId || this.contract,
        due_date: this.dueDate.toDateString()
      }
    },

    submit() {
      this.submitting = true

      this.$http
        .post(`/admins/transfers`, this.formData())
        .then((response) => {
          const data = response.data
          const path = `/administracao/gerenciamento/repasses/${data.id}`
          const route = this.$router.resolve({ path: path })

          window.open(route.href, '_blank')
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.create.failure"))

          const errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.errors = app.i18n.errify(errors, { model: "transfer" })
        })
        .finally(() => {
          this.submitting = false

          this.close()
        })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }

    p {
      font-size: 1.5rem;
      color: $second-color-light;
      overflow-wrap: break-word;
      white-space: pre-line;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
