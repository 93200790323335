<template lang="pug">
.landlord-section
  .inline-section
    input-field.two-columns(
      type="text"
      name="contract[landlord_attributes][name]"
      :placeholder="$t('.placeholder.landlordName')"
      :error="errors.landlordName"
      v-model="landlordName"
      required
    )

    cpf-cnpj-field.two-columns(
      name="contract[landlord_attributes][cpf_cnpj]"
      :placeholder="$t('.placeholder.landlordCpfCnpj')"
      :error="errors.landlordCpfCnpj"
      v-model="landlordCpfCnpj"
      required
    )
</template>

<script>
import StepCard from '../step-card.vue'
import CpfCnpjField from '../../../../../../../components/desktop/cpf-cnpj-field.vue'

export default {
  components: { StepCard, CpfCnpjField },

  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      landlordName: null,
      landlordCpfCnpj: null
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) }
  }
}
</script>
