<template lang="pug">
#page
  navbar(currentPage="adminManagementIndex")

  .score-report-container-phone
    breadcrumbs(:items="breadcrumbItems")

    expansible-card(title="Detalhes" :expanded="true")
        .info
          h3.info-title {{ $t('.details.createdAt') }}
          p.info-content
            | {{ $l('date.formats.default', scoreReport.createdAt) }}

        .info
          h3.info-title {{ $t('.details.createdBy.name') }}
          p.info-content
            | {{ scoreReport.createdBy ? scoreReport.createdBy.name : '-' }}

        .info
          h3.info-title {{ $t('.details.createdBy.role.label') }}
          p.info-content
            | {{ scoreReport.createdBy ? roleName(scoreReport.createdBy.role) : '-' }}

        .info
          h3.info-title {{ $t('.details.mainTenantEmail') }}
          p.info-content
            | {{ scoreReport.mainTenantEmail || '-' }}

        .info
          h3.info-title {{ $t('.details.mainTenantPhone') }}
          p.info-content
            | {{ scoreReport.mainTenantPhone || '-' }}

        .info
          h3.info-title {{ $t('.details.mainTenantZipCode') }}
          p.info-content
            | {{ scoreReport.mainTenantZipCode || '-' }}

        .info
          h3.info-title {{ $t('.details.propertyType') }}
          p.info-content
            | {{ propertyTypeTranslate(scoreReport.propertyType) }}

    manual-update(
      v-if="!scoreReport.contract && result",
      :result="result",
      :scoreReportId="getScoreReportParam",
      @updateScoreReport="initScoreReport"
    )

    .row.counter-container
      span.counter(v-if="!loading && scores.length > 0")
        | {{ $t('.totalCount', { count: scores.length }) }}
      span.counter(v-if="loading")
        | {{ $t('.totalCount.loading') }}

    .no-data-container(v-if="!loading && scores.length == 0")
      img.image(src="@/assets/images/public/no-data.svg")
      h2 {{ $t('.totalCount.zero') }}

    .cards-container
      .main-tenant(v-if="mainTenantScore")
        h2 {{ $t('.titles.mainTenant') }}

        score-card(
          :score="mainTenantScore",
          @re-fetch="reFetch"
        )

      .other-tenants(v-if="otherTenants.length")
        h2 {{ $t('.titles.otherTenants') }}

        score-card(
          v-for="score in otherTenants",
          :score="score",
          @re-fetch="reFetch"
        )
</template>

<script>
import objects from "lib/objects"
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"

import Navbar from "../../../components/navbar.vue"
import Breadcrumbs from '../../../../components/breadcrumbs.vue'
import ExpansibleCard from "../../../../components/expansible-card.vue"
import ManualUpdate from "../../../components/score_reports/manual-update.vue"
import ScoreCard from "../../../components/score_reports/score-card.vue"

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, ExpansibleCard, ManualUpdate, ScoreCard },

  beforeUnmount() {
    this.clearFetchInterval();
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.show",

      // tool
      routeName: "adminScoreReportShow",
      routePath: "administracao/gerenciamento/consultas",
      viewName: "show",

      // data
      loading: true,
      scoreReport: {},
      result: undefined,

      fetchInterval: null
    }
  },

  computed: {
    getScoreReportParam() {
      return this.$route.params.id
    },

    breadcrumbItems() {
      return [
        {
          name: this.$t('.breadcrumbs.management'),
          url: this.routePath.replace('/consultas', '')
        },
        {
          name: this.$t('.breadcrumbs.scores'),
          url:  this.routePath.replace('/consultas', ''),
          hash: 'scoreReports'
        },
        {
          name: `#${this.getScoreReportParam}`,
          url: `${this.routePath}/${this.getScoreReportParam}`
        }
      ]
    },

    scores() {
      return this.scoreReport.scores || []
    },

    mainTenantScore() {
      return this.scores.find(score => score.mainTenant)
    },

    otherTenants() {
      return this.scores.filter(score => !score.mainTenant)
    }
  },

  methods: {
    parseRoute() {},

    initScoreReport(scoreReport) {
      this.scoreReport = objects.camelize(scoreReport)

      if (this.scoreReport.status != 'wip') {
        this.clearFetchInterval()
      }

      this.initResult()
    },

    initResult() {
      if (this.scoreReport.result) {
        this.result = this.scoreReport.result
      }
    },

    reFetch() {
      this.fetchInterval = setInterval(() => {
        this.fetch();
      }, 5000);
    },

    clearFetchInterval() {
      clearInterval(this.fetchInterval);

      this.fetchInterval = null;
    },

    fetch() {
      return this.$http
        .get(`/admins/score-reports/${this.getScoreReportParam}.json`)
        .then(({ data }) => {
          this.initScoreReport(data)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    roleName(role) {
      return this.$t(`.details.createdBy.role.${role}`)
    },

    propertyTypeTranslate(type) {
      if (type) {
        return this.$t(
          `models.scoreReport.attributes.propertyTypeOptions.${type}`
        )
      }

      return '-'
    }
  }
}
</script>
<style scoped lang="scss">
.score-report-container-phone {
  padding: 2rem;
  width: 100%;

  .info {
    .info-title {
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: $second-color-light;
    }

    .info-content {
      font-size: 1.5rem;
      font-family: Lato;

      &.sent {
        background-color: $primary-color;
      }

      &.pending {
        background-color: $red-color;
      }
    }
  }

  .counter-container {
    margin-bottom: 1.5rem;
  }

  .counter {
    color: $grey-color-dark;
    font-style: italic;
  }

  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      width: 20rem;
      margin-bottom: 1rem;
    }

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
    }
  }

  .cards-container, .main-tenant, .other-tenants {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      margin: 0;
      color: $primary-color;
      font-size: 1.6rem;
      font-weight: 500;
      font-style: italic;
    }
  }
}
</style>
