<template lang="pug">
.bills-index
  expansible-card(:title="$t('.titles.overview')", :expanded="true")
    overview(
      v-if="$permissions.hasPermission('financial_infos_bills', 'get')"
      :items="overviewItems"
    )

  .bills-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          dropdown(
            v-if="dropDownCallbacks.length",
            :callbacks="dropDownCallbacks",
            icon="fa-download",
            orientation="left"
          )
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        date-field(
          v-model="query.dueDate",
          name="due-date"
          :label="$t('.label.dueDate')",
          :range="true"
        )

        date-field(
          v-model="query.paymentDate",
          name="payment-date"
          :label="$t('.label.paymentDate')",
          :range="true"
        )

        status-field(
          name="status",
          v-model="query.status"
        )

        agency-field(
          v-model="query.realEstateAgency",
          name="real_estate_agency",
          :label="$t('.label.realEstateAgency')",
          fetchUrl="/admins",
          path="real-estate/agencies",
          :disabled="!$permissions.hasPermission('real_estate_agencies', 'get')"
        )
          option(value="-1") {{ $t('none') }}

        input-field(
          v-model="query.contractCode",
          name="contract_code",
          :label="$t('.label.contractCode')",
          :placeholder="$t('.placeholder.contractCode')"
        )

        person-field(
          v-model="query.tenant",
          name="tenant",
          :label="$t('.label.tenant')",
          :placeholder="$t('.placeholder.tenant')"
        )

        select-field(
          v-model="query.regime"
          name="regime",
          :label="$t('.label.regime')",
          :placeholder="$t('.placeholder.regime')",
          :options="regimeOptions",
          :includeBlank="true"
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th
                  .checkbox
                    input(
                      type="checkbox",
                      @change="toogleAllInPageCheck"
                      :checked="allPageChecked || allChecked"
                    )

                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="bill in bills")
                td.checkbox
                  input(
                    type="checkbox",
                    @change="() => { toggleCheck(bill.id) }"
                    :checked="isChecked(bill.id) || allChecked"
                  )

                td.id(@click="openBill(bill.id)")
                  | {{ bill.id }}

                td.contract(@click="openBill(bill.id)")
                  .contract-info
                    span
                      b {{ $t('.table.contractInfo.code', { contractCode: bill.contractCode }) }}
                    span
                      | {{ `, ${bill.realEstateAgencyName}, ` }}
                    span
                      | {{ bill.contractAddress }}
                  .bank-info
                    span {{ $t('.table.bankInfo', bankInfos(bill)) }}

                td.due-date(@click="openBill(bill.id)")
                  | {{ $l('date.formats.default', bill.dueDate) }}

                td.payment-date(@click="openBill(bill.id)")
                  | {{ $l('date.formats.default', bill.paymentDate) }}

                td.credit-date(@click="openBill(bill.id)")
                  | {{ $l('date.formats.default', bill.creditDate) }}

                td.value(@click="openBill(bill.id)")
                  | {{ $t('.table.currency', { value: bill.value }) }}

                td.payer(@click="openBill(bill.id)")
                  | {{ billTranslate(`payerOptions.${bill.payer}`) }}

                td.status(@click="openBill(bill.id)")
                  .status-container(:class="bill.status")
                    | {{ billTranslate(`statusOptions.${getStatus(bill)}`) }}

                td.observation(@click="openBill(bill.id)")
                  | {{ bill.observation || '-' }}

              tr.loading(v-if="loading")
                td.checkbox
                  .cell

                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ $t('.pagination.text', { length: bills.length, total: pagination.total }) }}

      .actions-container
        table-selection-field(:callback="toogleAllChecked")

  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    reportPageUrl="/administracao/relatorios"
    :callback="() => showReportModal = false"
  )
</template>
<script>
import ExpansibleCard from "../../expansible-card.vue"
import AgencyField from "../../../../components/agency-field.vue"
import CheckboxField from "../../../../../../components/desktop/checkbox-field.vue"
import TableSelectionField from "../../../../../../components/desktop/table-selection-field.vue"
import Overview from "../../overview.vue"
import Dropdown from "../../../../components/dropdown.vue"
import FilterContainer from "../../../../components/filter-container.vue"
import StatusField from "../../bills/status-field.vue"
import ReportModal from "../../../../../../components/report-modal.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    AgencyField,
    CheckboxField,
    TableSelectionField,
    Overview,
    Dropdown,
    FilterContainer,
    StatusField,
    ReportModal
  },

  mounted() {
    this.fetch()
    this.fetchOverview()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.bills.index",

      // filters
      filterOpened: false,

      query: {
        realEstateAgency: null,
        contractCode: null,
        paymentDate: null,
        tenant: null,
        status: null,
        regime: 'cash_system',
        dueDate: (() => {
          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })(),
      },

      // table
      bills: [],
      pagination: { total: 0 },
      loading: false,
      allPageChecked: false,
      allChecked: false,
      checkedIds: [],

      // modals
      showLiquidateModal: false,
      showReverseModal: false,

      // overview
      overviewData: {
        total: { count: 0, value: 0 },
        normal: { count: 0, value: 0 },
        paid: { count: 0, value: 0 },
        unpaid: { count: 0, value: 0 },
        agreement: { count: 0, value: 0 }
      },

      regimeOptions: [
        { id: 'cash_system', text: 'Caixa' },
        { id: 'competence', text: 'Competência' }
      ],

      // report modal
      showReportModal: false,
      currentReportUrl: null
    }
  },

  methods: {
    init(bills, pagination) {
      this.bills.push(...objects.camelize(bills))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.bills = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get("/admins/financial/bills.json", { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const bills = body.data
          const pagination = body.pagination || {}

          this.init(bills, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get("/admins/financial/infos/bills.json", {
          params: this.httpParams()
        })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            total: { count: 0, value: 0 },
            normal: { count: 0, value: 0 },
            paid: { count: 0, value: 0 },
            unpaid: { count: 0, value: 0 },
            agreement: { count: 0, value: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    formattedRangeFilter(range) {
      if (!range) return null

      return { start_date: range[0], end_date: range[1] }
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        dueDate: this.formattedRangeFilter(this.query.dueDate),
        paymentDate: this.formattedRangeFilter(this.query.paymentDate),
        real_estate_agency: this.query.realEstateAgency,
        contract_code: this.query.contractCode,
        status: this.query.status,
        tenant: this.query.tenant,
        regime: this.query.regime,
        page: page
      })
    },

    isChecked(id) {
      return this.checkedIds.includes(id)
    },

    toogleAllChecked(event) {
      this.allPageChecked = false
      this.checkedIds = []
      this.allChecked = event == 1 ? true : false
    },

    toogleAllInPageCheck(event) {
      this.allChecked = false

      if (event.currentTarget.checked) {
        this.allPageChecked = true
        this.checkedIds = this.bills.map((transfer) => transfer.id)

        return
      }

      this.allPageChecked = false
      this.checkedIds = []
    },

    toggleCheck(id) {
      if (this.isChecked(id)) {
        this.allPageChecked = false
        this.checkedIds.splice(this.checkedIds.indexOf(id), 1)

        return
      }

      this.checkedIds.push(id)
    },

    openBill(billId) {
      this.$router.push({ path: `gerenciamento/cobrancas/${billId}` })
    },

    bankInfos(transfer) {
      return {
        bankCode: transfer.bankCode || "-",
        agency: transfer.agency || "-",
        account: transfer.account || "-",
      }
    },

    fileDownloadParams() {
      if (this.allChecked || this.checkedIds.length === 0) {
        return {
          select_all: true,
          due_date: this.formattedRangeFilter(this.query.dueDate),
          payment_date: this.formattedRangeFilter(this.query.paymentDate),
          real_estate_agency: this.query.realEstateAgency,
          status: this.query.status,
          contract_code: this.query.contractCode,
          tenant: this.query.tenant,
          regime: this.query.regime,
          except_ids: this.checkedIds
        }
      }

      return {
        bill_ids: this.checkedIds
      }
    },

    billTranslate(attribute) {
      return this.$t(`models.bill.attributes.${attribute}`)
    },

    getStatus(bill) {
      const billStatus = bill?.status

      if (!bill.bankSlipUrl && billStatus === 'waiting') {
        return 'waitingBankSlipCreate'
      }

      if (bill.awaitingBankSlipUpdate) return 'awaitingBankSlipUpdate'

      return billStatus
    },

    calculateBillsPercentage(partialCount) {
      if (partialCount == 0) return 0

      const totalCount = this.overviewData.total.count

      return (partialCount / totalCount * 100).toFixed(2)
    },

    generatePendantAndConsolidatedReceiptsReport() {
      const params = this.httpParams()
      params["select_all"] = true

      this.$http
        .get('/admins/bills/downloads/pendant-and-consolidated-receipts', {
          params: this.fileDownloadParams()
        }).then((response) => {
          const report = objects.camelize(response.data.data)

          this.currentReportUrl = `/admins/reports/${report.id}`
          this.showReportModal = true
        }).catch(() => {
          this.$notifications.error(this.$t(".notifications.download.failure"))
        })
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count,
          value: this.overviewData.total.value,
          percentage: this.overviewData.total.count > 0 ? 100 : 0
        },
        {
          description: this.$t(".overview.normal"),
          quantity: this.overviewData.normal.count,
          value: this.overviewData.normal.value,
          percentage: this.calculateBillsPercentage(this.overviewData.normal.count)
        },
        {
          description: this.$t(".overview.paid"),
          quantity: this.overviewData.paid.count,
          value: this.overviewData.paid.value,
          percentage: this.calculateBillsPercentage(this.overviewData.paid.count)
        },
        {
          description: this.$t(".overview.unpaid"),
          quantity: this.overviewData.unpaid.count,
          value: this.overviewData.unpaid.value,
          percentage: this.calculateBillsPercentage(this.overviewData.unpaid.count)
        },
        {
          description: this.$t(".overview.agreement"),
          quantity: this.overviewData.agreement.count,
          value: this.overviewData.agreement.value,
          percentage: this.calculateBillsPercentage(this.overviewData.agreement.count)
        }
      ]
    },

    dropDownCallbacks() {
      if (this.$permissions.hasPermission(
        "bills_downloads_pendant_and_consolidated_receipts", "get"
      )) {
        return [{
          name: this.$t(".dropDown.items.consolidatedReceipts"),
          callback: this.generatePendantAndConsolidatedReceiptsReport
        }]
      }

      return []
    },

    columnNames() {
      return [
        "id",
        "contractInfo",
        "dueDate",
        "paymentDate",
        "creditDate",
        "value",
        "payer",
        "status",
        "observation"
      ]
    },

    checkedCount() {
      if (this.allChecked)
        return this.pagination.total - this.checkedIds.length

      return this.checkedIds.length
    }
  }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(3, auto) max-content;
  column-gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.actions-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  .selected-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font-size: 1.4rem;

      margin: 0;
    }

    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      button {
        flex: 1;
        font-size: 1rem;

        padding: 0.5 1rem;
        margin: 0;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tr {
    min-width: 0 !important;

    td.id {
      min-width: min-content !important;
    }

    &:hover {
      td {
        background-color: darken($white-color-dark, 6%);

        &.contract,
        &.due-date,
        &.payment-date,
        &.status,
        &.observation,
        &.value {
          cursor: pointer;
        }
      }
    }
  }

  td {
    text-transform: capitalize !important;

    .contract-info {
      display: inline-block;
      vertical-align: top;

      width: 28.2rem;

      overflow-wrap: break-word;
      line-break: anywhere;
      white-space: normal;
    }

    .bank-info {
      color: $grey-color;
      font-size: 1.3rem;
      font-style: italic;
    }
  }

  .due-date,
  .payment-date,
  .value {
    min-width: 13rem !important;
  }

  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.waiting {
      background-color: $grey-color;
    }

    &.paid,
    &.manual_payment {
      background-color: $primary-color;
    }

    &.unpaid {
      background-color: $red-color;
    }

    &.agreement {
      background-color: $blue-color;
    }

    &.normal {
      background-color: $yellow-color;
    }

    &.canceled, &.chargeback {
      background-color: $second-color;
    }
  }

  .checkbox {
    min-width: 0 !important;
    height: 100%;

    display: flex;
    align-items: center;

    padding-right: 1rem;
    border-right: 1.5px solid $grey-color-light;

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;

      background-color: #fff;

      color: $primary-color;

      width: 2.5rem;
      height: 2.5rem;
      margin: 0;

      border: 0.16rem solid $second-color-light;
      border-radius: 0.2rem;

      transform: translateY(0.15rem);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 2.5rem;
        height: 2.5rem;

        transform: scale(0);
        transition: 120ms transform ease-in-out;

        box-shadow: inset 2.5em 2.5em $primary-color;
        border-radius: 0.2rem;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
}
</style>
