<template lang="pug">
.transfers-index
  expansible-card(:title="$t('.titles.overview')", :expanded="true")
    overview(
      v-if="$permissions.hasPermission('financial_infos_transfers', 'get')"
      :items="overviewItems"
    )

  .transfers-index-container
    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          dropdown(
            v-if="dropDownCallbacks.length",
            :callbacks="dropDownCallbacks",
            icon="fa-download",
            orientation="left"
          )
          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter
      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        liquidated-field(v-model="query.liquidated")

        date-field(
          v-model="query.liquidationDate",
          :disabled="!liquidated"
          :label="$t('.label.liquidationDate')",
          :range="true",
          name="liquidation_date"
        )

        date-field(
          v-model="query.dueDate",
          :label="$t('.label.dueDate')",
          :range="true",
          name="due_date"
        )

        agency-field(
          v-model="query.realEstateAgency",
          name="real_estate_agency",
          :label="$t('.label.realEstateAgency')",
          fetchUrl="/admins",
          path="real-estate/agencies",
          :disabled="!$permissions.hasPermission('real_estate_agencies', 'get')"
        )
          option(value="-1") {{ $t('none') }}

        input-field(
          v-model="query.contractCode",
          name="contract_code",
          :label="$t('.label.contractCode')",
          :placeholder="$t('.placeholder.contractCode')"
        )

        person-field(
          v-model="query.tenant",
          name="tenant",
          :label="$t('.label.tenant')",
          :placeholder="$t('.placeholder.tenant')"
        )

      .table-section
        .table-container(@scroll="autoLoad")
          table
            thead
              tr
                th
                  .checkbox
                    input(
                      type="checkbox",
                      @change="toogleAllInPageCheck"
                      :checked="allPageChecked || allChecked"
                    )
                th(v-for="name in columnNames")
                  p {{ $t(`.table.columns.${name}`) }}

            tbody
              tr(v-for="transfer in transfers")
                td.checkbox
                  input(
                    type="checkbox",
                    @change="() => { toggleCheck(transfer.id) }"
                    :checked="isChecked(transfer.id) || allChecked"
                  )

                td.id
                  | {{ transfer.id }}

                td.contract(@click="openTransfer(transfer.id)")
                  .contract-info
                    span
                      b {{ $t('.table.contractInfo.code', { contractCode: transfer.contractCode }) }}
                    span
                      | {{ `, ${transfer.realEstateAgencyName}, ` }}
                    span
                      | {{ transfer.propertyAddress }}
                  .bank-info
                    span {{ $t('.table.bankInfo', bankInfos(transfer)) }}

                td.due-date(@click="openTransfer(transfer.id)")
                  | {{ $l('date.formats.default', transfer.dueDate) }}

                td.liquidation-date(@click="openTransfer(transfer.id)")
                  | {{ $l('date.formats.default', transfer.liquidationDate) }}

                td.value(@click="openTransfer(transfer.id)")
                  | {{ $t('.table.currency', { value: transfer.value }) }}

                td.status(@click="openTransfer(transfer.id)")
                  .status-container(:class="transfer.status")
                    | {{ $t(`.table.status.${transfer.status}`) }}

              tr.loading(v-if="loading")
                td.checkbox
                  .cell
                td(v-for="cell in columnNames")
                  .cell
        footer
          p {{ $t('.pagination.text', { length: transfers.length, total: pagination.total }) }}

      .actions-container
        table-selection-field(:callback="toogleAllChecked")

        .selected-container(v-if="permissionToUpdate")
          p {{ $t('.table.actions.selectedItems', { count: checkedCount }) }}

          .buttons-container
            button.button-primary(
              @click.prevent="showLiquidateModal = true",
              :disabled="checkedCount < 1"
              )
              | {{ $t('.table.actions.buttons.liquidate') }}

            button.button-red(
              @click.prevent="showReverseModal = true",
              :disabled="checkedCount < 1"
              )
              | {{ $t('.table.actions.buttons.reverse') }}

  liquidate-modal(
    :callback="batchUpdate",
    :show="showLiquidateModal",
    @close="showLiquidateModal = false",
  )

  reverse-modal(
    :count="checkedCount",
    :callback="batchUpdate",
    :show="showReverseModal",
    @close="showReverseModal = false"
  )

  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    reportPageUrl="/administracao/relatorios"
    :callback="() => showReportModal = false"
  )

</template>
<script>
import ExpansibleCard from "../../expansible-card.vue"
import AgencyField from "../../../../components/agency-field.vue"
import CheckboxField from "../../../../../../components/desktop/checkbox-field.vue"
import TableSelectionField from "../../../../../../components/desktop/table-selection-field.vue"
import Overview from "../../overview.vue"
import Dropdown from "../../../../components/dropdown.vue"
import LiquidateModal from "./modals/liquidate.vue"
import ReverseModal from "./modals/reverse.vue"
import FilterContainer from "../../../../components/filter-container.vue"
import LiquidatedField from "./filters/liquidated-field.vue"
import ReportModal from "../../../../../../components/report-modal.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    AgencyField,
    CheckboxField,
    TableSelectionField,
    Overview,
    Dropdown,
    LiquidateModal,
    ReverseModal,
    FilterContainer,
    LiquidatedField,
    ReportModal
  },

  mounted() {
    if(!this.liquidated) {
      this.query.liquidationDate = null

      this.replaceQuery()
    }

    this.fetch()
    this.fetchOverview()
  },

  watch: {
    liquidated(value) {
      !value && (this.query.liquidationDate = null)
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.financial.transfers.index",

      // table
      transfers: [],
      pagination: { total: 0 },
      loading: false,
      allPageChecked: false,
      allChecked: false,
      checkedIds: [],

      // modals
      showLiquidateModal: false,
      showReverseModal: false,

      // overview
      overviewData: {
        total: { value: 0, count: 0 },
        pending: { value: 0, count: 0 },
        transfered: { value: 0, count: 0 }
      },

      // filter
      filterOpened: false,

      query: {
        liquidated: null,
        liquidationDate: null,
        realEstateAgency: null,
        contractCode: null,
        tenant: null,
        dueDate: (() => {
          const date = new Date()
          const firstDayOfMonth = new Date(
            date.getFullYear(), date.getMonth(), 1
          )
          const lastDayOfMonth = new Date(
            date.getFullYear(), date.getMonth() + 1, 0
          )

          return [firstDayOfMonth, lastDayOfMonth]
        })(),
      },

      columnNames: [
        "id", "contractInfo", "dueDate", "liquidationDate", "value", "status"
      ],

      // report modal
      showReportModal: false,
      currentReportUrl: null
    }
  },

  methods: {
    init(transfers, pagination) {
      this.transfers.push(...objects.camelize(transfers))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total"),
      }
    },

    filter() {
      this.replaceQuery()

      this.transfers = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get("/admins/financial/transfers.json", {
          params: this.httpParams(page),
        })
        .then((response) => {
          const body = response.data
          const transfers = body.data
          const pagination = body.pagination || {}

          this.init(transfers, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.allPageChecked = false
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get("/admins/financial/infos/transfers.json", {
          params: this.httpParams()
        })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            total: { value: 0, count: 0 },
            pending: { value: 0, count: 0 },
            transfered: { value: 0, count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if ((scrollTop + clientHeight < loadingHeight) || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    formattedRangeFilter(range) {
      if (!range) return null

      return { start_date: range[0], end_date: range[1] }
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        due_date: this.formattedRangeFilter(this.query.dueDate),
        liquidation_date: this.formattedRangeFilter(this.query.liquidationDate),
        real_estate_agency: this.query.realEstateAgency,
        contract_code: this.query.contractCode,
        liquidated: this.query.liquidated,
        tenant: this.query.tenant,
        page: page
      })
    },

    batchUpdateParams(liquidation_date = null) {
      if (this.allChecked || this.checkedIds.length === 0) {
        return {
          new_liquidation_date: liquidation_date,
          select_all: true,
          due_date: this.formattedRangeFilter(this.query.dueDate),
          liquidation_date: this.formattedRangeFilter(this.query.liquidationDate),
          real_estate_agency: this.query.realEstateAgency,
          contract_code: this.query.contractCode,
          liquidated: this.query.liquidated,
          tenant: this.query.tenant,
          except_ids: this.checkedIds
        }
      }

      return {
        new_liquidation_date: liquidation_date,
        transfer_ids: this.checkedIds
      }
    },

    batchUpdate(liquidation_date) {
      this.submitting = true

      this.$http
        .post(
          "/admins/financial/transfers/batch-changes",
          this.batchUpdateParams(liquidation_date)
        )
        .then(() => {
          this.allPageChecked = false
          this.allChecked = false
          this.checkedIds = []

          this.$notifications.info(
            this.$t(".notifications.batchUpdate.success")
          )

          this.filter()
        })
        .catch((err) => {
          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.$notifications.error(
            this.$t(".notifications.batchUpdate.failure")
          )
        })
        .finally(() => {
          this.submitting = false
        })
    },

    isChecked(id) {
      return this.checkedIds.includes(id)
    },

    toogleAllChecked(event) {
      this.tableSelectionField = null
      this.allPageChecked = false
      this.checkedIds = []
      this.allChecked = event == 1 ? true : false
    },

    toogleAllInPageCheck(event) {
      this.allChecked = false

      if (event.currentTarget.checked) {
        this.allPageChecked = true
        this.checkedIds = this.transfers.map((transfer) => transfer.id)

        return
      }

      this.allPageChecked = false
      this.checkedIds = []
    },

    toggleCheck(id) {
      if (this.isChecked(id)) {
        this.allPageChecked = false
        this.checkedIds.splice(this.checkedIds.indexOf(id), 1)

        return
      }

      this.checkedIds.push(id)
    },

    openTransfer(transferId) {
      this.$router.push({ path: `gerenciamento/repasses/${transferId}` })
    },

    bankInfos(transfer) {
      return {
        bankCode: transfer.bankCode || "-",
        agency: transfer.agency || "-",
        account: transfer.account || "-",
      }
    },

    generateReport(path) {
      this.$http.get(
        `/admins/transfers/downloads/${path}`,
        { params: this.batchUpdateParams() }
      ).then((response) => {
        const report = objects.camelize(response.data.data)

        this.currentReportUrl = `/admins/reports/${report.id}`
        this.showReportModal = true
      }).catch(() => {
        this.$notifications.error(this.$t(".notifications.download.failure"))
      })
    },

    calculateTransfersPercentage(partialCount) {
      if (partialCount == 0) return 0

      const totalCount = this.overviewData.total.count

      return (partialCount / totalCount * 100).toFixed(2)
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.total"),
          quantity: this.overviewData.total.count,
          value: this.overviewData.total.value,
          percentage: this.overviewData.total.count > 0 ? 100 : 0
        },
        {
          description: this.$t(".overview.pending"),
          quantity: this.overviewData.pending.count,
          value: this.overviewData.pending.value,
          percentage: this.calculateTransfersPercentage(this.overviewData.pending.count)
        },
        {
          description: this.$t(".overview.transfered"),
          quantity: this.overviewData.transfered.count,
          value: this.overviewData.transfered.value,
          percentage: this.calculateTransfersPercentage(this.overviewData.transfered.count)
        }
      ]
    },

    dropDownCallbacks() {
      const callbacks = []

      if (this.$permissions.hasPermission(
        "transfers_downloads_statements_pdfs", "get"
      )) {
        callbacks.push({
          name: this.$t(".dropDown.items.transferStatement"),
          callback: () => { this.generateReport("statements-pdf") }
        })
      }

      if (this.$permissions.hasPermission(
        "transfers_downloads_pendant_and_consolidated_expenses_reports", "get"
      )) {
        callbacks.push({
          name: this.$t(".dropDown.items.transferCompositionReport"),
          callback: () => {
            this.generateReport("pendant-and-consolidated-expenses-reports")
          }
        })
      }

      if (this.$permissions.hasPermission(
        "transfers_downloads_transfers_composition_reports", "get"
      )) {
        callbacks.push({
          name: this.$t(".dropDown.items.consolidatedExpenses"),
          callback: () => { this.generateReport("transfers-composition-report") }
        })
      }

      if (this.$permissions.hasPermission(
        "transfers_downloads_bills_and_transfers_csvs", "get"
      )) {
        callbacks.push({
          name: this.$t(".dropDown.items.billsAndTransfersReport"),
          callback: () => { this.generateReport("bills-and-transfers-csv") }
        })
      }

      return callbacks
    },

    checkedCount() {
      if (this.allChecked)
        return this.pagination.total - this.checkedIds.length

      return this.checkedIds.length
    },

    permissionToUpdate() {
      return this.$permissions.hasPermission(
        'financial_transfers_batch_changes', 'post'
      )
    },

    liquidated() {
      const liquidated = this.query.liquidated

      return !liquidated || liquidated == 'false' ? false : true
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  font-size: 2rem;
  font-weight: 500;
  color: $primary-color;
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

.actions-container {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  .selected-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    p {
      font-size: 1.4rem;

      margin: 0;
    }

    .buttons-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      button {
        flex: 1;
        font-size: 1rem;

        padding: 0.5 1rem;
        margin: 0;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.table-section {
  margin-top: 1.5rem;
}

.table-container {
  height: 40vh !important;
}

table {
  tr {
    min-width: 0 !important;

    td.id {
      min-width: min-content !important;
    }

    &:hover {
      td {
        background-color: darken($white-color-dark, 6%);

        &.contract,
        &.due-date,
        &.liquidation-date,
        &.value {
          cursor: pointer;
        }
      }
    }
  }

  td {
    text-transform: capitalize !important;

    .contract-info {
      display: inline-block;
      vertical-align: top;

      width: 28.2rem;

      overflow-wrap: break-word;
      line-break: anywhere;
      white-space: normal;
    }

    .bank-info {
      color: $grey-color;
      font-size: 1.3rem;
      font-style: italic;
    }
  }

  .due-date,
  .liquidation-date,
  .value {
    min-width: 13rem !important;
  }

  .status-container {
    border-radius: 0.5rem;

    width: min-content;

    color: $white-color;
    text-transform: uppercase;
    font-size: 1.4rem;

    padding: 0.5rem 2rem;

    &.sent_to_finance {
      background-color: $yellow-color;
    }

    &.liquidated {
      background-color: $primary-color;
    }

    &.canceled, &.chargeback {
      background-color: $red-color;
    }
  }

  .checkbox {
    min-width: 0 !important;
    height: 100%;

    display: flex;
    align-items: center;

    padding-right: 1rem;
    border-right: 1.5px solid $grey-color-light;

    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;

      background-color: #fff;

      color: $primary-color;

      width: 2.5rem;
      height: 2.5rem;
      margin: 0;

      border: 0.16rem solid $second-color-light;
      border-radius: 0.2rem;

      transform: translateY(0.15rem);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 2.5rem;
        height: 2.5rem;

        transform: scale(0);
        transition: 120ms transform ease-in-out;

        box-shadow: inset 2.5em 2.5em $primary-color;
        border-radius: 0.2rem;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
}
</style>
