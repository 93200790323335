<template lang="pug">
.contracts-index
  expansible-card(
    :title="$t('.titles.overview', { year: new Date().getFullYear()})",
    :expanded="true",
    uuid="admin-contracts-index-expansible-card-1"
  )
    overview(:items="overviewItems")

  .contracts-index-container
    expansible-card(
      :title="$t('.titles.pendingIndex')",
      :expanded="true",
      uuid="admin-contracts-index-expansible-card-2"
      )

      p {{ $t('.titles.pendingTitle') }}

      contracts-table(
        :loadCallback="pendingAutoLoad"
        :contracts="pendingContracts"
        :total="pendingPagination.total"
      )

    card
      .filtering-container
        h2 {{ $t('.titles.index') }}

        .actions
          dropdown(
            :callbacks="dropDownCallbacks"
            icon="fa-download"
            orientation="left"
          )

          button.icon.button-primary(
            @click="filterOpened = !filterOpened"
            :class="{ 'opened': filterOpened }"
          )
            i.fa.fa-filter

      filter-container(
        :filter="filter"
        :opened="filterOpened"
        :i18nPath="defaultI18nScope"
      )
        agency-field(
          v-model="query.realEstateAgency"
          name="real_estate_agency"
          :label="$t('.label.realEstateAgency')"
          fetchUrl="/admins"
          path="real-estate/agencies"
        )
          option(value="-1") {{ $t('none') }}

        input-field(
          v-model="query.contractCode"
          name="contract_code"
          :label="$t('.label.contractCode')"
          :placeholder="$t('.placeholder.contractCode')"
        )

        person-field(
          v-model="query.tenant"
          name="tenant"
          :label="$t('.label.tenant')"
          :placeholder="$t('.placeholder.tenant')"
        )

        status-field(name="status" v-model="query.status")

        date-field(
          v-model="query.createdAt"
          name="created_at"
          :label="$t('.label.createdAt')"
          :range="true"
        )

      contracts-table(
        :loadCallback="autoLoad"
        :contracts="contracts"
        :total="pagination.total"
      )

  report-modal(
    :show="showReportModal",
    :reportApiUrl="currentReportUrl",
    reportPageUrl="/administracao/relatorios",
    :callback="() => showReportModal = false"
  )

</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import AgencyField from "../../../components/agency-field.vue"
import FilterContainer from "../../../components/filter-container.vue"
import Overview from "../overview.vue"
import Dropdown from "../../../components/dropdown.vue"
import ContractsTable from "../../components/contracts/contracts-table.vue"
import StatusField from "./status-field.vue"
import ReportModal from "../../../../../components/report-modal.vue"

import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    AgencyField,
    FilterContainer,
    Overview,
    Dropdown,
    ContractsTable,
    StatusField,
    ReportModal
  },

  mounted() {
    this.fetch()
    this.fetchPending()
    this.fetchOverview()
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.contracts.index",

      // filter
      filterOpened: false,

      query: {
        realEstateAgency: null,
        contractCode: null,
        tenant: null,
        createdAt: null,
        status: null
      },

      contracts: [],
      pendingContracts: [],
      pagination: { total: 0 },
      pendingPagination: { total: 0 },
      loading: false,

      // overview
      overviewData: {
        current_day: { count: 0 },
        current_week: { count: 0 },
        current_month: { count: 0 },
        current_year: { count: 0 }
      },

      // report modal
      showReportModal: false,
      currentReportUrl: null
    }
  },

  methods: {
    init(contracts, pagination) {
      this.contracts.push(...objects.camelize(contracts))

      this.pagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total")
      }
    },

    initPending(contracts, pagination) {
      this.pendingContracts.push(...objects.camelize(contracts))

      this.pendingPagination = {
        first: objects.dig(pagination, "first", "page"),
        prev: objects.dig(pagination, "prev", "page"),
        next: objects.dig(pagination, "next", "page"),
        last: objects.dig(pagination, "last", "page"),
        total: objects.dig(pagination, "total")
      }
    },

    filter() {
      this.replaceQuery()

      this.contracts = []

      this.fetch()
      this.fetchOverview()
      this.filterOpened = false
    },

    fetch(page = null) {
      this.loading = true

      this.$http
        .get('/admins/contracts.json', { params: this.httpParams(page) })
        .then((response) => {
          const body = response.data
          const contracts = body.data
          const pagination = body.pagination || {}

          this.init(contracts, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchPending(page = null) {
      this.loading = true

      const params = { pending: true, page: page, issuer: 'alpop' }

      this.$http
        .get('/admins/contracts.json', { params: params })
        .then((response) => {
          const body = response.data
          const contracts = body.data
          const pagination = body.pagination || {}

          this.initPending(contracts, pagination)
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchOverview() {
      this.$http
        .get('/admins/infos/contracts.json', { params: this.httpParams() })
        .then((response) => {
          const overview = response.data

          this.overviewData = overview
        })
        .catch(() => {
          this.overviewData = {
            current_day: { count: 0 },
            current_week: { count: 0 },
            current_month: { count: 0 },
            current_year: { count: 0 }
          }

          this.$notifications.error(
            this.$t(".notifications.fetchOverview.failure")
          )
        })
    },

    autoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if (scrollTop + clientHeight < loadingHeight || !this.pagination.next) {
        return
      }

      this.loading = true

      this.fetch(this.pagination.next)
    },

    pendingAutoLoad(event) {
      if (this.loading) return

      const { scrollTop, scrollHeight, clientHeight } = event.target
      const loadingHeight = scrollHeight - 5

      if (scrollTop + clientHeight < loadingHeight || !this.pendingPagination.next) {
        return
      }

      this.loading = true

      this.fetchPending(this.pendingPagination.next)
    },

    formattedRangeFilter(range) {
      if (!range) return

      return { start_date: range[0], end_date: range[1] }
    },

    httpParams(page = null) {
      return this.$params.toRouteParams({
        real_estate_agency: this.query.realEstateAgency,
        code: this.query.contractCode,
        tenant: this.query.tenant,
        status: this.query.status,
        created_at: this.formattedRangeFilter(this.query.createdAt),
        page: page
      })
    },

    parseRoute() {},

    openContract(contractId) {
      this.$router.push({ path: `gerenciamento/contratos/${contractId}` })
    },

    generateReport(path) {
      this.$http
        .get(`/admins/${path}`, { params: this.httpParams() })
        .then((response) => {
          const report = objects.camelize(response.data.data)

          this.currentReportUrl = `/admins/reports/${report.id}`
          this.showReportModal = true
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.download.failure"))
        })
    }
  },

  computed: {
    overviewItems() {
      return [
        {
          description: this.$t(".overview.current_day"),
          quantity: this.overviewData.current_day.count
        },
        {
          description: this.$t(".overview.current_week"),
          quantity: this.overviewData.current_week.count
        },
        {
          description: this.$t(".overview.current_month"),
          quantity: this.overviewData.current_month.count
        },
        {
          description: this.$t(".overview.current_year"),
          quantity: this.overviewData.current_year.count
        }
      ]
    },

    dropDownCallbacks() {
      return [
        {
          name: this.$t(".dropDown.contractsReport"),
          callback: () => {
            this.generateReport('contracts/downloads/contracts-csv')
          }
        },
        {
          name: this.$t(".dropDown.clickSignSecondAssignment"),
          callback: () => {
            this.generateReport('contracts/downloads/click-sign-contracts-csv')
          }
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: repeat(2, auto) repeat(2, max-content);
  gap: 0.5em;

  .button-primary {
    margin-bottom: 1.4rem;
    margin-top: auto;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  p,
  button {
    margin: 0;
  }
}

.contracts-index-container {
  p {
      margin: 0;
      font-size: 1.6rem;
  }
}

.filtering-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    color: $primary-color;
  }

  .actions {
    display: flex;

    button.opened {
      opacity: 0.5;
    }

    button.icon {
      font-size: 1.5rem;
      width: 4rem;
      padding: 0;
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}
</style>
