<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    .info-container
      h1 {{ $t(".title") }}

      form(ref="form", action="/", @submit.prevent="createManager")
        .create-manager-container(:class="hasError && 'error'")
          input-field(
            type="text"
            name="manager[name]"
            :label="$t('.labels.name')"
            :error="errors.name"
          )

          input-field(
            type="email"
            name="manager[email]"
            :label="$t('.labels.email')"
            :error="errors.email"
          )

          .buttons-container
            button.close(
              type="cancel"
              @click="close"
            )
              | {{ $t(".buttons.close") }}
            button.confirm(
              type="submit"
              :disabled="submitting"
            )
              | {{ $t(".buttons.confirm") }}
</template>
<script>
import objects from "lib/objects"
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.team.managers.createModal",

      prefixUrlAgency: this.$user.prefixUrlAgency,

      submitting: false,
      errors: {},
      hasError: false
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  methods: {
    openModal(event) {
      event.preventDefault()
      this.showModal()
    },

    close(event) {
      event.preventDefault()
      this.closeModal()
    },

    createManager() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      this.hasError = false

      this.$http
        .post(`/${this.prefixUrlAgency}/managers`, formData)
        .then((data) => {
          this.errors = {}
          this.$notifications.clear()
          this.$emit('update', data.data)
          this.closeModal()
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.failure"))

          let errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
          this.errors = app.i18n.errify(errors, {
            model: "real_estate_user",
          })
        })
        .then(() => {
          this.submitting = false
        })
    },
  }
}
</script>
<style scoped lang="scss">
.open-modal {
  width: 100%;
  margin: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 90%;
  height: fit-content;

  @media (orientation: landscape) {
    width: 60%;
  }

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 4rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close{
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
