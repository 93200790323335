<template lang="pug">
Modal(:show="show",  :onClose="close")
  .sign-up-container
    .header-container
      h2 {{ $t('.title') }}
      p {{ $t('.subtitle') }}

    .form-container
      form(ref="form", action="/", @submit.prevent="submit")
        input-field(
          type="text",
          name="real_estate_lead[name]"
          :label="$t('.label.name')"
          :placeholder="$t('.placeholder.name')"
          :error="errors.name && $t(`errors.${errors.name}`)"
          required
        )

        email-field(
          name="real_estate_lead[email]"
          :label="$t('.label.email')"
          :placeholder="$t('.placeholder.email')"
          :error="errors.email && $t(`errors.${errors.email}`)"
          required
        )

        phone-field(
          name="real_estate_lead[phone_number]"
          :label="$t('.label.phone')"
          :placeholder="$t('.placeholder.phone')"
          :error="errors.phoneNumber && $t(`errors.${errors.phoneNumber}`)"
          required
        )

        input-field(
          type="text",
          name="real_estate_lead[real_estate_name]"
          :label="$t('.label.realEstateName')"
          :placeholder="$t('.placeholder.realEstateName')"
          :error="errors.realEstateName && $t(`errors.${errors.realEstateName}`)"
          required
        )

        input-field(
          type="text",
          name="real_estate_lead[city]"
          :label="$t('.label.city')"
          :placeholder="$t('.placeholder.city')"
          :error="errors.city && $t(`errors.${errors.city}`)"
          required
        )

        select-field(
          v-model="realEstateEmployee"
          :options="realEstateEmployeeOptions"
          :includeBlank="true"
          :label="$t('.label.realEstateEmployee')"
          required
        )

        select-field(
          v-model="role"
          :options="roleOptions"
          :includeBlank="true"
          :label="$t('.label.role')"
          required
        )

        button.button-primary(type="submit" :disabled="submiting")
          | {{ $t('.buttons.submit') }}
</template>
<script>
import objects from "lib/objects"

import Modal from '../../../components/modal.vue'

export default {
  components: { Modal },

  props: {
    show: { type: Boolean, default: false }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "app.signUpModal",

      // form
      errors: {},
      submitting: false,

      role: null,
      realEstateEmployee: null
    }
  },

  methods: {
    close() {
      this.role = null
      this.realEstateEmployee = null

      this.$emit('close')
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      formData.set('real_estate_lead[role]', this.role)
      formData.set('real_estate_lead[real_estate_employee]', this.realEstateEmployee)

      this.$http.post('real_estate/leads', formData)
        .then(() => {
          this.errors = {}

          this.$notifications.clear()
          this.close()

          this.$notifications.info(this.$t(".notifications.submit.success"))
        })
        .catch((err) => {
          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    realEstateEmployeeOptions() {
      return [
        { id: true, text: this.$t('true') },
        { id: false, text: this.$t('false') }
      ]
    },

    roleOptions() {
      return [
        { id: 'owner', text: this.$t('.roles.owner') },
        { id: 'founder', text: this.$t('.roles.founder') },
        { id: 'manager', text: this.$t('.roles.manager') },
        { id: 'director', text: this.$t('.roles.director') },
        { id: 'other', text: this.$t('.roles.other') }
      ]
    }
  }

}
</script>
<style lang="scss" scoped>
  .sign-up-container {
    width: 42rem;

    .header-container {
      text-align: center;

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .form-container {
      button {
        margin-top: 2rem;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .sign-up-container {
      width: 100%;
    }
  }
</style>