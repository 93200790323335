import './jquery'
import './lodash.isblank'
import './select2'
import './dropzone'
import './google-analytics'
import './google-tag-manager'
import './datadog'

//
//  TODO é possível fazer `import` do jQuery antes de todos e depois
//  deixar o require recursivo (ex abaixo)? Pois precisamos dar precedência
//  ao jQuery (acho), para que os demais initializers funcionem com acesso ao
//  `$` global.
//
// EXEMPLO:
// ----
//
// Carrega todos os initializers em ordem alfabética! (Webpack)
// - initializer: arquivos .js nesse diretório
// - não recursivo! (segundo argumento `false`)
//
// const initializers = require.context('.', false, /\.js$/)
// initializers.keys().forEach(initializers)


// Apenas em ambiente de testes, carregue initializers específicos.
// Aqui carregamos, por ex, o interceptor que loga todas as XHR, para facilitar
// identificar problemas em specs.
if (app.env === 'test') {
  const testInitializers = require.context('./test', false, /\.js$/)
  testInitializers.keys().forEach(testInitializers)
}
