<template lang="pug">
#page.real-estate-agency-page
  navbar(currentPage="adminRealEstateAgencyIndex")

  loading-div.real-estate-container
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          :class="currentPage == 'details' && 'actived'",
          @click="() => { setCurrentPage('details') }"
        )
          | {{ $t('.menus.details') }}

        button(
          :class="currentPage == 'users' && 'actived'",
          @click="() => { setCurrentPage('users') }"
        )
          | {{ $t('.menus.users') }}

    .current-page-container
      breadcrumbs(:items="currentBreadcrumb")

      Details(v-if="currentPage == 'details'")
      users(v-if="currentPage == 'users'")

</template>
<script>
import strings from "lib/strings"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import Navbar from "../../components/navbar.vue"
import Details from '../../components/real_estate_agencies/details.vue'
import Users from '../../components/real_estate_agencies/users/index.vue'

export default {
  components: { Navbar, Details, Breadcrumbs, Users },

  data() {
    return {
      defaultI18nScope: "admin.realEstateAgency.show",

      routePath: "administracao/imobiliarias",
    }
  },

  methods: {
    fetch() {},

    parseRoute() {},

    setCurrentPage(anchor) {
      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    }
  },

  computed: {
    getIdParam() {
      return this.$route.params.id
    },

    currentPage() {
      return this.$route.hash.slice(1) || 'details'
    },

    currentBreadcrumb() {
      const page = strings.capitalize(this.currentPage)

      return eval(`this.breadcrumb${page}`)
    },

    defaultBreadcrumbs() {
      return [
        {
          name: this.$t('.breadcrumbs.realEstateAgencies'),
          url: this.routePath
        },
        {
          name: `#${this.getIdParam}`,
          url: `${this.routePath}/${this.getIdParam}`,
          hash: this.currentPage
        }
      ]
    },

    breadcrumbDetails() {
      return [
        ...this.defaultBreadcrumbs,
        {
          name: this.$t('.breadcrumbs.details'),
          url: `${this.routePath}/${this.getIdParam}`,
          hash: this.currentPage
        }
      ]
    },

    breadcrumbUsers() {
      return [
        ...this.defaultBreadcrumbs,
        {
          name: this.$t('.breadcrumbs.users'),
          url: `${this.routePath}/${this.getIdParam}`,
          hash: this.currentPage
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  .real-estate-container {
    .content {
      display: flex;
      width: 100vw;
      overflow: hidden;
    }
  }
</style>

<style lang="scss" scoped>
#page.real-estate-agency-page {
  overflow: hidden;
  height: 100%;
}

.real-estate-container {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: visible;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .current-page-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow: scroll;
    overflow-y: visible;
    padding-bottom: 5rem;
  }
}
</style>
