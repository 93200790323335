<template lang="pug">
.expansible-container(
  ref="expansibleContainer",
  :style="`height: ${containerHeight}`"
)
  slot

.showing-children-container(@click="toggleExpansion")
  p {{ getShowingChildren }}
  a(v-if="childrenCount > fixedChildren") {{ getShowingText }}
</template>

<script>
export default {
  data() {
    return {
      defaultI18nScope: "realEstateAdmin.contracts.card",

      childrenCount: 0,
      unexpandedHeight: "auto",
      containerHeight: "auto",
      expanded: false
    }
  },

  props: {
    fixedChildren: { type: Number, required: true }
  },

  mounted() {
    this.childrenCount = this.$refs.expansibleContainer.childElementCount
    this.setUnexpandedHeight()

    this.containerHeight = this.unexpandedHeight
  },

  computed: {
    getShowingChildren() {
      const count = this.expanded ? this.childrenCount : this.fixedChildren
      const params = { count: count, total: this.childrenCount }

      return this.$t('.showing.count', params)
    },

    getShowingText() {
      return this.$t(this.expanded ? '.buttons.showOne' : '.buttons.showAll')
    }
  },

  methods: {
    setUnexpandedHeight() {
      const expansibleContainer = this.$refs.expansibleContainer
      const children = expansibleContainer.children

      if (!children) return

      let height = 0

      for (let i = 0; i <= (this.fixedChildren - 1); i++) {
        height += children[i].offsetHeight
      }

      this.unexpandedHeight = `${height}px`
    },

    toggleExpansion() {
      this.containerHeight = this.expanded ? this.unexpandedHeight : "auto"

      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped lang="scss">
.expansible-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1.5px solid $primary-color;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.showing-children-container {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  margin-top: -1rem;
  margin-bottom: -2rem;

  p {
    font-size: 1.3rem;
    color: $grey-color;
  }

  a {
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
