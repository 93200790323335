<template lang="pug">
#page.real-estate-agencies-page
  navbar

  loading-div.admin-real-estate-agencies-container
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          :class="currentPage == 'agencies' && 'actived'",
          @click="() => { setCurrentPage('agencies') }"
        )
          | {{ $t('.agencies') }}

        button(
          :class="currentPage == 'leads' && 'actived'",
          @click="() => { setCurrentPage('leads') }"
        )
          | {{ $t('.leads') }}

    .real-estate-agencies-container
      breadcrumbs(:items="breadcrumb")

      agencies(v-if="currentPage == 'agencies'")
      leads(v-if="currentPage == 'leads'")
</template>
<script>
import tool from "lib/vue-tool"

import Breadcrumbs from '../../../components/breadcrumbs.vue'
import Navbar from "../../components/navbar.vue"
import Agencies from "../../components/real_estate_agencies/index.vue"
import Leads from "../../components/real_estate_agencies/leads/index.vue"

export default {
  mixins: [tool],
  components: { Breadcrumbs, Navbar, Agencies, Leads },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.realEstateAgency.index",

      // tool
      routeName: "adminRealEstateAgencyIndex",
      routePath: "administracao/imobiliarias",

      loading: true,

      permissions: ['agencies', 'leads']
    }
  },

  methods: {
    fetch() {},

    parseRoute() {},

    setCurrentPage(anchor) {
      if (this.$route.hash.slice(1) == anchor) return

      this.$router.push({name: this.routeName, hash: `#${anchor}` })
    }
  },

  computed: {
    currentPage() {
      const hash = this.$route.hash.slice(1)

      if (this.permissions.includes(hash)) return hash

      if (hash) this.$router.replace({ hash: null })

      return this.permissions[0]
    },

    breadcrumb() {
      if (this.currentPage == 'agencies') {
        return [
          {
            name: this.$t('.agencies'),
            url: this.routePath,
            hash: this.currentPage
          }
        ]
      }

      return [
        {
          name: this.$t('.agencies'),
          url: this.routePath,
          hash: this.currentPage
        },
        {
          name: this.$t('.leads'),
          url: this.routePath,
          hash: 'leads'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.admin-real-estate-agencies-container {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.real-estate-agencies-page {
  overflow: hidden;
  height: 100%;
}

.admin-real-estate-agencies-container {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: visible;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .real-estate-agencies-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow: scroll;
    overflow-y: visible;
    padding-bottom: 5rem;
  }
}
</style>