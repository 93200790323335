<doc lang="markdown">
  Autenticação

  Formulário de autenticação, exibido quando necessário pelo VueRouter
</doc>

<template lang="pug">
#page
  .tool.login.sheet
    h1 {{ heading ? heading : $t('.heading') }}

    form.login(ref="form" action="/" @submit.prevent="submit")
      .row
        .twelve.columns
          input-field(
            :autofocus="true"
            type="email"
            v-model="email"
            name="email"
            :placeholder="$t('.placeholder.email')"
          )

      .row
        .twelve.columns
          input-field(
            v-model="password"
            name="password"
            type="password"
            :placeholder="$t('.placeholder.password')"
          )

      .row
        .twelve.columns
          button.button-primary.u-full-width(
            type="submit" :disabled="authenticating"
          )
            | {{ submitText }}

      .row
        .twelve.columns
          router-link(
            :to="{ name: `${this.role}NewPassword` }"
            data-action="forgot-password"
          )
            | {{ $t('.button.forgotPassword') }}
</template>

<script>

export default {
  props: {
    redirect: { type: String, required: true }, // default para rota "root"
    role: { type: String },
    url: { type: String },
    heading: { type: String }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "login",

      authenticating: false,

      // form
      password: null
    }
  },

  computed: {
    submitText() {
      return this.$t(
        `.button.${this.authenticating ? 'authenticating' : 'authenticate'}`
      )
    }
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.authenticating = true
      this.$http
        .post(`${this.url}/sign_in`, formData)
        .then((response) => {
          const { data } = response

          // apaga possíveis notificações de erro anteriores
          this.$notifications.clear()

          // atualiza access token
          app.authToken.accessToken = response.headers[
            app.authToken.accessTokenHEADER
          ]

          // atualiza client
          app.authToken.client = response.headers[app.authToken.clientHEADER]

          // atualiza uid
          app.authToken.uid = response.headers[app.authToken.uidHEADER]

          // autentica o usuário no client
          app.auth.authenticate(data.data, this.role)

          this.$router.replace({ path: this.redirect })
        })
        .catch((err) => {
          const response = err.response

          if (response == null) {
            return this.$notifications.error(
              this.$t(".notifications.submit.failure")
            )
          }

          const { errors } = response.data

          errors.forEach(element => { this.$notifications.error(element) })
        })
        .then(() => {
          // finally
          this.authenticating = false

          this.$refs.form.reset()
        })
    }
  }
}
</script>

<style scoped lang="scss">
#page {
  background-color: $grey-color-high-light;
}

.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;

  h1 {
    font-size: 20px;
    letter-spacing: 0.3px;
  }
}
</style>