<template lang="pug">
.expansible-container(:class=" isExpanded && 'expanded'")
  .title(@click="toggle")
    h2
      | {{ title }}
    i.fa(:class=" isExpanded ? 'fa-angle-down' : 'fa-angle-up'")
  .expansible
    slot
</template>
<script>
export default {
  props: {
    title: { type: String, required: true },
    expanded: { type: Boolean, default: false },
    uuid: { type: String }
  },

  data() {
    return {
      isExpanded: false
    }
  },

  beforeMount() {
    if (!this.uuid) {
      this.isExpanded = this.expanded

      return
    }

    const savedState = localStorage.getItem(this.uuid)

    this.isExpanded = savedState ? JSON.parse(savedState) : this.expanded
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded

      if (this.uuid) {
        localStorage.setItem(this.uuid, this.isExpanded)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.expansible-container {
  background-color: $white-color;
  padding: 2.5rem;
  padding-bottom: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  margin-bottom: 3rem;

  .expansible {
    display: none;
  }

  &.expanded {
    padding-bottom: 2rem;

    .expansible {
      display: block;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    i {
      font-size: 2.5rem;
      color: $primary-color;
    }

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: $primary-color;
    }
  }
}
</style>