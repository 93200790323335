<template lang="pug">
.table-section
  .table-container
    table
      thead
        tr
          th(v-for="name in columnNames")
            p {{ $t(`.${stepName}.columns.${name}`) }}

          th(v-if="hasOpenData")

      tbody
        tr(v-for="item in tableData")
          td(v-for="name in columnNames")
            | {{ formattedInfo(item, name) }}

          td.open-button(v-if="hasOpenData")
            i.fa.fa-external-link(
              v-if="item.openData"
              @click="open(item.openData)"
            )

        tr.loading(v-if="loading")
          td(v-for="cell in columnNames")
            .cell
  footer
    p {{ $t(`.${stepName}.footer`, { count: tableData.length }) }}
</template>
<script>
import currencyFormatter from 'lib/currency-formatter'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "admin.scoreReports.scoreCard.scoreSteps.details",
    }
  },

  props: {
    stepName: { type: String, required: true },
    tableData: { type: Array, default: () => ([]) },
    columnNames: { type: Array, default: () => ([]) },
    dateColumns: { type: Array, default: () => ([]) },
    dateTimeColumns: { type: Array, default: () => ([]) },
    currencyColumns: { type: Array, default: () => ([]) }
  },

  computed: {
    hasOpenData() {
      return this.tableData.some(item => item.openData)
    }
  },

  methods: {
    formattedInfo(item, name) {
      const data = item[name]

      if (!data || data === '0001-01-01T00:00:00.000-03:06') return '-'

      switch (true) {
        case this.dateColumns.includes(name):
          return this.$l('date.formats.default', data)
        case this.dateTimeColumns.includes(name):
          return this.$l('time.formats.micro', data)
        case this.currencyColumns.includes(name):
          return currencyFormatter.format(data)
        case typeof data === 'boolean':
          return this.$t(`.format.stepTable.values.${data}`)
        default:
          return data
      }
    },

    open(data) {
      const route = this.$router.resolve(data)

      window.open(route.href, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.table-container {
  height: 100% !important;

  table {
    td.open-button {
      min-width: 1rem;

      i {
        cursor: pointer;
        color: $primary-color;
        font-weight: 700;
      }
    }
  }
}

footer {
  p {
    margin: 0;
  }
}



</style>
