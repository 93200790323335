<template lang="pug">
Modal(v-model="isShow", :close="close")
  .modal
    h1 {{ $t('.title') }}

    p {{ $t('.description') }}

    form(ref="form")
      select-field(
        name="attachment",
        :label="$t('.label.attachmentType')",
        :error="errors.document",
        :options="filteredOptions",
        v-model="attachmentType",
        required
        includeBlank
      )

      file-field(
        type="file",
        :name="fileInputName",
        :accept="acceptedFormats",
        :label="$t('.label.document')",
        :error="errors.document",
        @input="val => { document = val }"
        required
        destroyable
      )

    .buttons-container
      button.close(@click="close" :disabled="submitting")
        | {{ $t(".buttons.close") }}

      button.confirm(@click="submit" :disabled="disableSubmit")
        | {{ $t(".buttons.confirm") }}
</template>

<script>
import objects from "lib/objects"
import { ref } from 'vue'

export default {
  data() {
    return {
      options: [
        { id: 'rental_contract', text: 'Contrato de locação' },
        { id: 'admin_contract', text: 'Contrato de administração' },
        { id: 'procuration', text: 'Procuração' }
      ],
      attachmentType: null,

      // i18n
      defaultI18nScope: "components.contract.files.uploadModal",

      // form
      acceptedFormats: ".pdf,.docx,.doc",

      // submit
      document: null,
      submitting: false,
      errors: {}
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    show: { type: Boolean, default: false },
    callback: { type: Function, default: () => {} },
    documents: { type: Array, default: () => [] },
    contractId: { type: Number, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    disableSubmit() {
      return !this.document || this.submitting
    },

    filteredOptions() {
      const fileNames = this.documents.map(file => file.fileName)

      const filteredOptions = this.options.filter(
        option => !fileNames.includes(option.text)
      )

      return filteredOptions
    },

    fileInputName() {
      return this.attachmentType ? `contract[${this.attachmentType}]` : null
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)
      const config = { headers: { "Content-Type": "multipart/form-data" } }

      this.$http
        .put(`admins/contracts/${this.contractId}`, formData, config)
        .then(() => {
          this.callback()

          this.$emit('close')

          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          const errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )

          this.errors = app.i18n.errify(errors, { model: "document" })
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  height: fit-content;
  max-width: 60%;

  h1 {
    font-size: 1.7rem;
    font-weight: 550;
    color: $second-color-dark;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: $second-color-light;
    overflow-wrap: break-word;
    white-space: pre-line;
    margin: 1rem 0;
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 5rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}
</style>
