<template lang="pug">
.edit-manager-modal
  button.open-modal.button-blue(@click="openModal")
    | {{ $t('.openModal') }}
  Modal(v-model="isShow", :close="close")
    .modal
      .info-container
        h1 {{ $t(".title") }}

        form(ref="form", action="/", @submit.prevent="updateManager(id)")
          .edit-manager-container(:class="hasError && 'error'")
            input-field(
              v-model="name"
              type="text"
              name="manager[name]"
              :label="$t('.labels.name')"
              :error="errors.name"
            )
            input-field(
              v-model="email"
              type="email"
              name="manager[email]"
              :label="$t('.labels.email')"
              :error="errors.email"
            )
            input(
              type='checkbox'
              name="manager[lock]"
              class='ios8-switch'
              id="manager_lock"
              :checked="blocked"
              :error="errors.lockedAt"
            )
            label(for="manager_lock")
              | {{ $t(".blockAcess") }}
            .buttons-container
              button.close(
                type="cancel"
                @click="close"
              )
                | {{ $t(".buttons.close") }}
              button.confirm(
                type="submit"
                :disabled="submitting"
              )
                | {{ $t(".buttons.confirm") }}
</template>
<script>
import objects from "lib/objects"
import { ref } from 'vue'

export default {
  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.team.managers.editModal",

      prefixUrlAgency: this.$user.prefixUrlAgency,

      submitting: false,
      errors: {},
      hasError: false
    }
  },

  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  props: {
    name: { type: String, required: true },
    email: { type: String, required: true},
    blocked: { type: Boolean, required: true },
    id: { type: Number, required: true }
  },

  methods: {
    openModal(event) {
      event.preventDefault()
      this.showModal()
    },

    close(event) {
      event.preventDefault()
      this.closeModal()
    },

    updateManager(id) {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      let lockValue = formData.get("manager[lock]")

      if(lockValue == "on") {
        formData.set("manager[lock]", true)
      } else {
        formData.set("manager[lock]", false)
      }

      this.hasError = false

      this.$http
        .put(`/${this.prefixUrlAgency}/managers/${id}`, formData)
        .then((data) => {
          this.errors = {}
          this.$notifications.clear()
          this.$emit('update', data.data)
          this.closeModal()
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.failure"))

          let errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
          this.errors = app.i18n.errify(errors, {
            model: "real_estate_user",
          })
        })
        .then(() => {
          this.submitting = false
        })
    },
  }
}
</script>
<style scoped lang="scss">
.open-modal {
  width: 100%;
  margin: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 90%;
  height: fit-content;

  @media (orientation: landscape) {
    width: 60%;
  }

  .info-container {
    min-height: 11em;

    h1 {
      font-size: 1.7rem;
      font-weight: 550;
      color: $second-color-dark;
    }
  }

  .buttons-container {
    display: grid;
    justify-content: flex-end;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    width: 100%;
    margin-top: 4rem;

    button {
      width: fit-content;
      margin-bottom: 0;
    }

    .confirm {
      color: $white-color;
      background-color: $primary-color;
    }

    .close{
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }
  }
}

input[type="checkbox"].ios8-switch {
  position: absolute;
  display: none;
  margin: 8px 0 0 16px;
}
input[type="checkbox"].ios8-switch + label {
  position: relative;
  line-height: 2.0em;
}
input[type="checkbox"].ios8-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  right: 0%;
  top: 0;
  width: 40px; /* x*5 */
  height: 24px; /* x*3 */
  border-radius: 16px; /* x*2 */
  background: $white-color;
  border: 1px solid $grey-color-light;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  right: 0%;
  margin-right: 16px;
  top: 0px;
  width: 24px; /* x*3 */
  height: 24px; /* x*3 */
  border-radius: 16px; /* x*2 */
  background: $white-color;
  border: 1px solid $grey-color-light;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:hover:after {
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch:checked + label:after {
  margin-right: 0;
}
input[type="checkbox"].ios8-switch:checked + label:before {
  background: $primary-color;
}

/* SMALL */

input[type="checkbox"].ios8-switch-sm {
  margin: 5px 0 0 10px;
}
input[type="checkbox"].ios8-switch-sm + label {
  position: relative;
  padding: 0 0 0 32px;
  line-height: 1.3em;
}
input[type="checkbox"].ios8-switch-sm + label:before {
  width: 25px; /* x*5 */
  height: 15px; /* x*3 */
  border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:after {
  width: 15px; /* x*3 */
  height: 15px; /* x*3 */
  border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:hover:after {
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-sm:checked + label:after {
  margin-left: 10px; /* x*2 */
}

/* LARGE */

input[type="checkbox"].ios8-switch-lg {
  margin: 10px 0 0 20px;
}
input[type="checkbox"].ios8-switch-lg + label {
  position: relative;
  padding: 7px 0 0 60px;
  line-height: 2.3em;
}
input[type="checkbox"].ios8-switch-lg + label:before {
  width: 50px; /* x*5 */
  height: 30px; /* x*3 */
  border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:after {
  width: 30px; /* x*3 */
  height: 30px; /* x*3 */
  border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:hover:after {
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-lg:checked + label:after {
  margin-left: 20px; /* x*2 */
}
</style>

<style lang="scss">
div .vue-universal-modal {
  background-color: rgba($second-color-light, 0.5);
}
</style>
