<template lang="pug">
.tool.password.new.sheet
  h1 {{ $t('.heading') }}

  p {{ $t('.instructions') }}

  form(ref="form" action="/" @submit.prevent="submit")
    input(type="hidden" name="redirect_url" value="")

    .row
      .twelve.columns
        input-field(
          type="email"
          v-model="email"
          name="email"
          :placeholder="$t('.placeholder.email')"
          :error="errors.email"
        )

    .row
      .twelve.columns
        button.button-primary.u-full-width(
          type="submit" :disabled="submitting"
        )
          | {{ submitBtnText }}
</template>

<script>
import objects from "lib/objects";

export default {
  props: {
    redirect: { type: String, required: true },
    url: { type: String, required: true },
    model: { type: String, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "password.new",

      submitting: false,

      // form
      errors: {},
      email: null
    }
  },

  computed: {
    submitBtnText() {
      return this.$t(`.button.${this.submitting ? 'submitting' : 'submit'}`)
    }
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.submitting = true

      this.$http
        .post(this.url, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.$router.replace({ path: this.redirect })
        })
        .catch((err) => {
          const errors = objects.dig(err, "response", "data", "errors")

          if (errors instanceof Array) {
            errors.forEach((error) => {
              if (typeof error == 'string') {
                this.$notifications.error(error)
              }
            })
          } else {
            this.$notifications.error(this.$t(".notifications.submit.failure"))

            this.errors = objects.camelize(errors || {})
          }
        })
        .then(() => {
          // finally
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;

  h1 {
    font-size: 28px;
  }
}
</style>
