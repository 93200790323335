<template lang="pug">
#page.cnab-page
  navbar

  loading-div.cnab-container-desktop
    .menu-container
      h2 {{ $t('.menu') }}

      .menu-items
        button(
          :class="currentPage == 'remittance' && 'actived'",
          @click="currentPage = 'remittance'"
        )
          | {{ $t('.remittance') }}

        button(
          :class="currentPage == 'return' && 'actived'",
          @click="currentPage = 'return'"
        )
          | {{ $t('.return') }}

    .cnab-container
      breadcrumbs(
        :items="currentPage == 'remittance' ? breadcrumbRemittance : breadcrumbReturn"
      )

      remittance-page(v-if="currentPage == 'remittance'")
      return-page(v-if="currentPage == 'return'")
</template>
<script>
import tool from "lib/vue-tool"
import vueAuthenticate from "lib/vue-authenticate"
import Navbar from "../../components/navbar.vue"
import Breadcrumbs from '../../../components/breadcrumbs.vue'
import RemittancePage from '../../components/cnab/remittance-page.vue'
import ReturnPage from '../../components/cnab/return-page.vue'

export default {
  mixins: [tool, vueAuthenticate],
  components: { Navbar, Breadcrumbs, RemittancePage, ReturnPage },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.cnab.index",

      // tool
      routeName: "realEstateCnabIndex",
      routePath: "imobiliaria/cnab",
      viewName: "index",

      currentPage: 'remittance',

      loading: true
    }
  },

  created() {
    this.showCnab()
  },

  computed: {
    breadcrumbRemittance() {
      return [
        {
          name: this.$t('.cnab'),
          url: this.routePath
        },
        {
          name: this.$t('.remittance'),
          url: this.routePath
        }
      ]
    },

    breadcrumbReturn() {
      return [
        {
          name: this.$t('.cnab'),
          url: this.routePath
        },
        {
          name: this.$t('.return'),
          url: this.routePath
        }
      ]
    }
  },

  methods: {
    fetch() {},

    parseRoute() {},

    showCnab() {
      const config = this.$firebase.remoteConfigValue('enableCnabPage')
      const roleValue = config[app.auth.role]

      if (!roleValue || !roleValue.includes(app.auth.user_id)) {
        this.$router.replace({ path: '/acesso-nao-autorizado' })
      }
    }
  }
}
</script>
<style lang="scss">
.cnab-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
#page.cnab-page {
  overflow: hidden;
  height: 100%;
}

.cnab-container-desktop {
  padding: 0;
  margin: 0 !important;
  width: 100%;
  max-width: fit-content;
  height: 100%;
  overflow: hidden;

  .menu-container {
    width: 25%;
    height: 100vh;
    overflow-y: auto;

    background-color: $white-color-dark;
    box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);

    padding: 0 1.5rem;

    h2 {
      color: $primary-color;
      font-size: 2rem;
      font-weight: 400;
      border-bottom: 1.5px solid $primary-color;
      margin-top: 2.5rem;
    }

    .menu-items {
      width: 100%;
      height: max-content;

      button {
        text-align: start;
        color: $second-color-light;
        font-weight: 400;
        border-radius: 0.5rem;
        border: none;
        padding: 0.5rem 1.5rem;
        height: auto;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: none;

        &.actived{
          background-color: $primary-high-light;
          color: $primary-color;
        }

        &:hover {
          background-color: darken($primary-high-light, 5%);
          color: $primary-color;
        }
      }
    }
  }

  .cnab-container {
    width: 75%;
    height: 100vh;
    padding: 2.5rem;
    overflow-y: auto;
    padding-bottom: 10rem;
  }
}
</style>