<template lang="pug">
.tool.password.edit.sheet
  h1 {{ $t('.heading') }}

  form(ref="form", action="/", @submit.prevent="submit")
    input(type="hidden", name="reset_password_token", :value="resetPasswordToken")
    input-error(:message="errors.resetPasswordToken")

    .row
      .twelve.columns
        input-field(
          v-model="password",
          name="password",
          type="password",
          :label="$t('.label.password')",
          :placeholder="$t('.placeholder.password')",
          :error="errors.password"
        )
    .row
      .twelve.columns
        input-field(
          v-model="passwordConfirmation",
          name="password_confirmation",
          type="password",
          :label="$t('.label.passwordConfirmation')",
          :placeholder="$t('.placeholder.passwordConfirmation')",
          :error="errors.passwordConfirmation"
        )
    .row
      .twelve.columns
        button.button-primary.u-full-width(
          type="submit",
          :disabled="submitting"
        )
          | {{ submitBtnText }}

    p.password-requirements {{ $t('.passwordRequirements') }}
</template>

<script>
import objects from "lib/objects"

export default {
  props: {
    redirect: { type: String, required: true },
    url: { type: String, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "password.edit",

      submitting: false,

      // form
      errors: {},
      resetPasswordToken: this.$route && this.$route.query.reset_password_token,
      password: null,
      passwordConfirmation: null,
    }
  },

  computed: {
    submitBtnText() {
      if (this.submitting) {
        return this.$t(".button.submitting")
      }

      return this.$t(".button.submit")
    }
  },

  methods: {
    submit() {
      const formData = new FormData(this.$refs.form)
      this.submitting = true

      this.$http
        .put(this.url, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.$router.replace({ path: this.redirect })
        })
        .catch((err) => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))

          this.errors = objects.camelize(
            objects.dig(err, "response", "data", "errors") || {}
          )
        })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  background: inherit;
  border: none;
  text-align: start;
  padding: none;
}

.sheet {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 340px;
  font-weight: 300;
  font-size: 16px;

  h1 {
    font-size: 20px;
    letter-spacing: 0.3px;
  }

  .password-requirements {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: $grey-color-dark;
    margin: 0;
  }
}
</style>
