<template lang="pug">
.bill-section
  .inline-section
    currency-field.three-columns(
      name="contract[fire_insurance_amount]"
      :placeholder="$t('.placeholder.fireInsuranceAmount')"
      :error="errors.fireInsuranceAmount"
      @input="fetchNewFee"
      v-model="fireInsuranceAmount"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[fire_insurance_installments]"
      :placeholder="$t('.placeholder.fireInsuranceInstallments')"
      :error="errors.fireInsuranceInstallments"
      v-model="fireInsuranceInstallments"
      required
    )

    currency-field.three-columns(
      name="contract[condominium_amount]"
      :placeholder="$t('.placeholder.condominiumAmount')"
      :error="errors.condominiumAmount"
      @input="fetchNewFee"
      v-model="condominiumAmount"
      required
    )

  .inline-section
    currency-field.three-columns(
      name="contract[iptu_amount]"
      :placeholder="$t('.placeholder.iptuAmount')"
      :error="errors.iptuAmount"
      @input="fetchNewFee"
      v-model="iptuAmount"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[iptu_installments]"
      :placeholder="$t('.placeholder.iptuInstallments')"
      :error="errors.iptuInstallments"
      v-model="iptuInstallments"
      required
    )

    input-field.three-columns(
      type="number"
      name="contract[iptu_exemption_months]"
      :placeholder="$t('.placeholder.iptuExemptionMonths')"
      :error="errors.iptuExemptionMonths"
      v-model="iptuExemptionMonths"
      required
    )

  .inline-section
    currency-field.three-columns(
      name="contract[rent_discount]"
      :placeholder="$t('.placeholder.rentDiscount')"
      :error="errors.rentDiscount"
      v-model="rentDiscount"
    )

    input-field.three-columns(
      type="number"
      name="contract[rent_discount_months]"
      :placeholder="$t('.placeholder.rentDiscountMonths')"
      :error="errors.rentDiscountMonths"
      v-model="rentDiscountMonths"
    )

    select-field.three-columns(
      name="contract[closed_month]"
      :options="closedMonthOptions"
      :error="errors.closedMonth"
      v-model="closedMonth"
      includeBlank
      required
    )

  .hided-fee-container(v-if="hasHidedServices")
    h3 {{ $t('.form.alpopFee') }}

    p {{ $t('.form.fields.alpopNewFee') }} <b>{{ updatedFee }}%</b>
</template>

<script>
import ToggleableInputs from "../../../toggleable-inputs.vue"

export default {
  components: { ToggleableInputs },

  data() {
    return {
      // i18n
      defaultI18nScope: 'realEstate.management.contract.new',

      // form fields data
      fireInsuranceAmount: null,
      fireInsuranceInstallments: null,
      condominiumAmount: null,
      iptuAmount: null,
      iptuInstallments: null,
      iptuExemptionMonths: null,
      rentDiscount: null,
      rentDiscountMonths: null,
      closedMonth: null
    }
  },

  props: {
    errors: { type: Object, default: () => ({}) },
    scoreReport: { type: Object, default: null },
    fetchNewFee: { type: Function, default: () => {} },
    newFee: { type: Number, default: null }
  },

  computed: {
    updatedFee() {
      if (!this.scoreReport) return

      return this.newFee || this.scoreReport.fee
    },

    hasHidedServices() {
      if (!this.scoreReport) return

      return this.scoreReport.hidedServices.length > 0
    },

    closedMonthOptions() {
      return [
        {
          id: 'opened',
          text: this.$t('.closedMonthOptions.opened')
        },
        {
          id: 'closed',
          text: this.$t('.closedMonthOptions.closed')
        }
      ]
    }
  }
}
</script>
