<template lang="pug">
.field.select-field(:class="{ error, disabled }")
  label(v-if="!hideLabel", :for="inputId", :class="{ 'required': required }")
    | {{ labelText }}

  select2(
    :id="inputId",
    :disabled="disabled",
    :name="name",
    :options="selectedOption",
    :overlay="overlay",
    :placeholder="placeholder",
    type="integer",
    :value="modelValue",
    :include-blank="true",
    :hide-search="false",
    :required="required",
    @input="(val) => { $emit('update:modelValue', val) }"
    :url="formattedUrl",
    :dataFormatting="dataFormatting",
    :processResults="processResults"
  )
    slot
  input-error(:message="error")
</template>

<script>
import vueAuthenticate from "lib/vue-authenticate"
import SelectField from "src/components/phone/select-field.vue"

export default {
  mixins: [vueAuthenticate],
  extends: SelectField,

  props: {
    fetchUrl: { type: String, required: true },
    path: { type: String, default: 'real-estate-agencies' }
  },

  mounted() {
    if (this.modelValue) {
      this.$http
        .get(`${this.formattedUrl}/${this.modelValue}`)
        .then(({ data }) => {
          this.selectedOption = [{ id: data.id, text: data.name }]
        })
    }
  },

  data() {
    return {
      defaultI18nScope: "components.agencyField",

      selectedOption: []
    }
  },

  computed: {
    formattedUrl() {
      return `${this.fetchUrl}/${this.path}`
    }
  },

  methods: {
    processResults (response) {
      const body = response.data
      const agencies = body.data
      const pagination = body.pagination || {}

      return {
        results: agencies.map((agency) => (
          { id: agency.id, text: agency.name }
        )),
        pagination: {
          'more': !!pagination.next
        }
      };
    },

    dataFormatting (params) {
      return { page: params.page || 1, name: params.term }
    }
  },
}
</script>

<style scoped lang="scss">
label.required::after {
  content: ' *';
  color: $primary-color
}
</style>
