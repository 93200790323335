<template lang="pug">
.editable-input-field
  p.info-content(
    v-if="editingDisabled"
    @click="editingDisabled = false"
  )
    | {{ getValue }}

  .edit-container(v-else)
    form(ref="form" action="/" @submit.prevent="submit")
      cpf-cnpj-field(
        :name="name"
        v-model="inputValue"
        hideLabel
      )

      .buttons
        button.button-primary(
          type="submit"
          :disabled="submitting"
        )
          i.fa.fa-check

        button.button-primary.close(
          :disabled="submitting"
          @click="cancelEdition"
        )
          i.fa.fa-times
</template>

<script>
import CpfCnpjField from "../cpf-cnpj-field.vue"

export default {
  extends: CpfCnpjField,

  props: {
    name: { type: String, required: true },
    modelValue: { type: [String, Number] },
    id: { type: [String, Number] },
    type: { type: String, default: "text" },
    path: { type: String, required: true }
  },

  data() {
    return {
      defaultI18nScope: "components.editableInputField",

      submitting: false,
      editingDisabled: true,
      inputValue: null
    }
  },

  beforeMount() {
    this.setupValue()
  },

  watch: {
    modelValue() {
      this.setupValue()
    }
  },

  methods: {
    setupValue() {
      this.inputValue = this.modelValue
    },

    cancelEdition() {
      this.setupValue()

      this.editingDisabled = true
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      if (this.id) {
        const formattedIdKey = this.name.replace(/\[([^\]]+)\]$/, "[id]")

        formData.set(formattedIdKey, this.id)
      }

      this.$http
        .put(this.path, formData)
        .then(() => {
          this.$emit('update:modelValue', formData.get(this.name))

          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.setupValue()

          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .then(() => {
          this.submitting = false
          this.editingDisabled = true
        })
    }
  },

  computed: {
    getValue() {
      return this.modelValue || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.editable-input-field {
  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    input {
      width: 100%;
      padding: 0px 10px;
      font-family: Lato;
      font-size: 1.5rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }
    }
  }

  p {
    &.info-content {
      padding: 0px 0px;
      font-size: 1.5rem;
      font-family: Lato;

      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }
}
</style>