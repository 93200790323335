<template lang="pug">
.overview-container(v-if="contract")
  .buttons-container
    //- dropdown(
    //-   v-if="contract.filesUrls.length > 0",
    //-   :urls="translateDropDownUrls",
    //-   :title="$t('.actions.dropDown.title')"
    //- )

  expansible-card(:title="$t('.contractInfo')", :expanded="true")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.code') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          name="contract[code]",
          :value="contract.code",
          :path="contractPath"
        )
        p.info-content(v-else) {{ contract.code || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.createdAt') }}
        .loading-content(v-if="loading")
        p.info-content(v-else-if="contract.createdAt")
          | {{ $l('date.formats.default', contract.createdAt) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.startDate') }}
        .loading-content(v-if="loading")
        editable-date-field(
          v-else-if="contract.editable",
          name="contract[start_date]",
          :value="contract.startDate",
          :path="contractPath"
        )
        p.info-content(v-else-if="contract.createdAt")
          | {{ $l('date.formats.default', contract.startDate) }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.monthsUntilDeadline') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          type="number",
          name="contract[months_until_deadline]",
          :value="contract.monthsUntilDeadline",
          :path="contractPath"
        )
        p.info-content(v-else) {{ contract.monthsUntilDeadline || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.dueDay') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          type="number",
          name="contract[due_day]",
          :value="('0' + contract.dueDay).slice(-2)",
          :path="contractPath"
        )
        p.info-content(v-else)
          | {{ contract.dueDay ? ("0" + contract.dueDay).slice(-2) : '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.readjustmentMonth') }}
        .loading-content(v-if="loading")
        editable-months-field(
          v-else-if="contract.editable",
          name="contract[readjustment_month]",
          :value="contract.readjustmentMonth",
          :path="contractPath"
        )
        p.info-content(v-else-if="contract.readjustmentMonth")
          | {{ $t('date.month_names')[contract.readjustmentMonth] }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentValue') }}
        .loading-content(v-if="loading")
        editable-currency-field(
          v-else-if="contract.editable",
          v-model="contract.rentValue",
          name="contract[rent_value]",
          :value="contract.rentValue",
          :path="contractPath"
        )
        p.info-content(v-else) R$ {{ contract.rentValue || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.contractType') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="contract[contract_type]",
          :v-model="contract.contractType",
          :value="contract.contractType",
          :options="contractTypeOptions",
          :path="contractPath"
        )
        p.info-content(v-else-if="contract.contractType")
          | {{ $t(`models.contract.contractType.${contract.contractType}`) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.readjustmentIndex') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="contract[readjustment_index]",
          :value="contract.readjustmentIndex",
          :options="readjustmentIndexOptions",
          :path="contractPath"
        )
        p.info-content(v-else-if="contract.readjustmentIndex")
          | {{ $t(`models.contract.readjustmentIndex.${contract.readjustmentIndex}`) }}
        p.info-content(v-else) -

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.paymentType') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="contract[payment_type]",
          :value="contract.paymentType",
          :options="paymentTypeOptions",
          :path="contractPath"
        )
        p.info-content(v-else-if="contract.paymentType")
          | {{ $t(`models.contract.paymentType.${contract.paymentType}`) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.status') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="contract[status]",
          :value="contract.status",
          :options="statusOptions",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else-if="contract.status")
          | {{ $t(`models.contract.status.${contract.status}`) }}
        p.info-content(v-else) -

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.terminationDate') }}
        .loading-content(v-if="loading")
        editable-date-field(
          v-else-if="contract.editable",
          name="contract[termination_date]",
          :value="contract.terminationDate",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else-if="contract.terminationDate")
          | {{ $l('date.formats.default', contract.terminationDate) }}
        p.info-content(v-else) -

    .inline-section
      .full-size
        h3.info-title {{ $t('models.contract.attributes.observation') }}
        .loading-content.observation(v-if="loading")
        editable-text-area-field(
          v-else-if="contract.editable",
          name="contract[observation]",
          :value="contract.observation",
          :path="contractPath"
        )
        p.info-content(v-else) {{ contract.observation || '-' }}

  files(:documents="contract.filesUrls" :onUpdate="onUpdate" :contractId="contract.id")

  expansible-card(:title="$t('.billingInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.fireInsuranceAmount') }}
        .loading-content(v-if="loading")
        editable-currency-field(
          v-else-if="contract.editable",
          v-model="contract.fireInsuranceAmount",
          name="contract[fire_insurance_amount]",
          :value="contract.fireInsuranceAmount",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) R$ {{ contract.fireInsuranceAmount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.fireInsuranceInstallments') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          v-model="contract.fireInsuranceInstallments",
          name="contract[fire_insurance_installments]",
          type="number",
          :value="contract.fireInsuranceInstallments",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) {{ contract.fireInsuranceInstallments || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.condominiumAmount') }}
        .loading-content(v-if="loading")
        editable-currency-field(
          v-else-if="contract.editable",
          v-model="contract.condominiumAmount",
          name="contract[condominium_amount]",
          :value="contract.condominiumAmount",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) R$ {{ contract.condominiumAmount || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuAmount') }}
        .loading-content(v-if="loading")
        editable-currency-field(
          v-else-if="contract.editable",
          v-model="contract.iptuAmount",
          name="contract[iptu_amount]",
          :value="contract.iptuAmount",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) R$ {{ contract.iptuAmount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuInstallments') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          v-model="contract.iptuInstallments",
          name="contract[iptu_installments]",
          type="number",
          :value="contract.iptuInstallments",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) {{ contract.iptuInstallments || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.iptuExemptionMonths') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          v-model="contract.iptuExemptionMonths",
          name="contract[iptu_exemption_months]",
          type="number",
          :value="contract.iptuExemptionMonths",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) {{ contract.iptuExemptionMonths || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentDiscount') }}
        .loading-content(v-if="loading")
        editable-currency-field(
          v-else-if="contract.editable",
          v-model="contract.rentDiscount",
          name="contract[rent_discount]",
          :value="contract.rentDiscount",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) R$ {{ contract.rentDiscount || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rentDiscountMonths') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          v-model="contract.rentDiscountMonths",
          name="contract[rent_discount_months]",
          type="number",
          :value="contract.rentDiscountMonths",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else) {{ contract.rentDiscountMonths || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.closedMonth') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="contract[closed_month]",
          :v-model="contract.closedMonth",
          :value="contract.closedMonth",
          :options="closedMonthTypeOptions",
          :path="contractPath",
          :callback="onUpdate"
        )
        p.info-content(v-else)
          | {{ $t(`models.contract.attributes.closedMonthOptions.${contract.closedMonth}`)}}

  expansible-card(:title="$t('.landlordInfo')")
    .inline-section
      .four-columns
        h3.info-title {{ $t('models.contract.attributes.landlordName') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-else-if="contract.editable",
          name="landlord[name]",
          :value="contract.landlordAttributes.name",
          :path="landlordPath"
        )
        p.info-content(v-else) {{ contract.landlordAttributes.name || '-' }}

      .four-columns
        h3.info-title {{ $t('models.contract.attributes.landlordCpfCnpj') }}
        .loading-content(v-if="loading")
        editable-cpf-cnpj-field(
          v-else-if="contract.editable",
          name="landlord[cpf_cnpj]",
          v-model="contract.landlordAttributes.cpfCnpj",
          :path="landlordPath"
        )
        p.info-content(v-else) {{ contract.landlordAttributes.cpfCnpj || '-' }}

  expansible-card(:title="$t('.tenantInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.name') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.tenantAttributes.name || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.cpf') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ contract.tenantAttributes.cpf || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.rg') }}
        .loading-content(v-if="loading")
        editable-input-field(
          name="tenant[rg]",
          :value="contract.tenantAttributes.rg",
          :path="tenantPath"
        )

    .inline-section
      .three-columns(v-for="(title, index) in emailTitles")
        h3.info-title {{ $t(`models.contract.attributes.${title}`) }}
        .loading-content(v-if="loading")
        editable-input-field(
          name="tenant[emails_attributes][][address]",
          :value="tenantEmails[index] ? tenantEmails[index].address : null",
          :id="tenantEmails[index] ? tenantEmails[index].id : ''",
          :path="tenantPath"
        )

    .inline-section
      .three-columns(v-for="(title, index) in phoneTitles")
        h3.info-title {{ $t(`models.contract.attributes.${title}`) }}
        .loading-content(v-if="loading")
        editable-input-field(
          name="tenant[phones_attributes][][number]",
          :value="tenantPhones[index] ? tenantPhones[index].number : null",
          :id="tenantPhones[index] ? tenantPhones[index].id : ''",
          :path="tenantPath",
          mask="(##) #####-####"
        )

  expansible-card(:title="$t('.propertyInfo')")
    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.propertyType') }}
        .loading-content(v-if="loading")
        editable-select-field(
          v-else-if="contract.editable",
          name="property[property_type]",
          :v-model="contract.propertyAttributes.propertyType",
          :value="contract.propertyAttributes.propertyType",
          :options="propertyTypeOptions",
          :path="propertyPath"
        )
        p.info-content(v-else)
          | {{ propertyTypeTranslate(contract.propertyAttributes.propertyType) }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.zipCode') }}
        .loading-content(v-if="loading")
        p.info-content(
          v-else-if="!editZipCode",
          :class="{ clickable: contract.editable }",
          @click="contract.editable ? editZipCode = true : null"
        )
          | {{ contract.propertyAttributes.zipCode || '-' }}
        .edit-container(v-else)
          form(ref="form", action="/", @submit.prevent="submitAddress")
            input-field(
              :modelValue="contract.propertyAttributes.zipCode",
              @input="getAddress",
              mask="#####-###",
              hideLabel
            )

            .buttons
              button.button-primary(
                type="submit",
                :class="submitting && 'button-progress'"
                :disabled="waitingZipCodeInput || submitting",
                @click="editPropertyNumber"
              )
                i.fa.fa-check

              button.button-primary.close(
                type="button",
                :disabled="submitting",
                @click="resetAddressValues"
              )
                i.fa.fa-times

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.state') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ address.state || contract.propertyAttributes.state || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.city') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ address.city || contract.propertyAttributes.city || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.neighborhood') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ address.neighborhood || contract.propertyAttributes.neighborhood || '-' }}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.street') }}
        .loading-content(v-if="loading")
        p.info-content(v-else) {{ address.street || contract.propertyAttributes.street || '-' }}

    .inline-section
      .three-columns
        h3.info-title {{ $t('models.contract.attributes.number') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-if="contract.editable",
          ref="propertyNumberField",
          name="property[number]",
          :value="contract.propertyAttributes.number",
          :path="propertyPath"
        )
        p.info-content(v-else) {{ contract.propertyAttributes.number || '-'}}

      .three-columns
        h3.info-title {{ $t('models.contract.attributes.complement') }}
        .loading-content(v-if="loading")
        editable-input-field(
          v-if="contract.editable",
          name="property[complement]",
          :value="contract.propertyAttributes.complement",
          :path="propertyPath"
        )
        p.info-content(v-else) {{ contract.propertyAttributes.complement || '-' }}

      .three-columns

</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import EditableInputField from "../../../../../components/desktop/editable-fields/editable-input-field.vue"
import EditableCurrencyField from "../../../../../components/desktop/editable-fields/editable-currency-field.vue"
import EditableTextAreaField from "../../../../../components/desktop/editable-fields/editable-text-area-field.vue"
import EditableDateField from "../../../../../components/desktop/editable-fields/editable-date-field.vue"
import EditableMonthsField from "../../../../../components/desktop/editable-fields/editable-months-field.vue"
import EditableSelectField from "../../../../../components/desktop/editable-fields/editable-select-field.vue"
import EditableCpfCnpjField from "../../../../../components/desktop/editable-fields/editable-cpf-cnpj-field.vue"

import cep from 'cep-promise'
import Files from "./files/index.vue"
import strings from "lib/strings"
import objects from "lib/objects"

export default {
  components: {
    ExpansibleCard,
    EditableCurrencyField,
    EditableInputField,
    EditableTextAreaField,
    EditableDateField,
    EditableMonthsField,
    EditableSelectField,
    EditableCpfCnpjField,
    Files
  },

  props: {
    loading: { type: Boolean, required: false },
    contract: { type: Object, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.contracts.show",

      showModal: false,
      showDiscardErrorModal: false,
      loadingModal: false,

      contractTypes: [
        'residential',
        'non_residential',
        'commercial',
        'industrial',
        'temporary_house',
        'by_order',
        'mixed'
      ],

      propertyTypes: [
        'house',
        'condominium_house',
        'commercial',
        'apartment',
        'roof_top',
        'flat'
      ],

      closedMonthTypes: ['closed', 'opened'],

      emailTitles: ['firstEmail', 'secondEmail', 'thirdEmail'],

      phoneTitles: ['firstPhone', 'secondPhone', 'thirdPhone'],

      address: {
        zipCode: null,
        state: null,
        neighborhood: null,
        city: null,
        street: null
      },

      editZipCode: false,
      waitingZipCodeInput: true,
      submitting: false
    }
  },

  computed: {
    tenantEmails() {
      return this.contract.tenantAttributes.emailsAttributes.map(
        email => ({ id: email.id, address: email.address })
      )
    },

    tenantPhones() {
      return this.contract.tenantAttributes.phonesAttributes.map(
        phone => ({ id: phone.id, number: phone.number })
      )
    },

    contractTypeOptions() {
      return this.contractTypes.map(contractType => {
        return {
          id: contractType,
          text: this.$t(`models.contract.contractType.${contractType}`)
        }
      })
    },

    propertyTypeOptions() {
      return this.propertyTypes.map(propertyType => {
        return {
          id: propertyType,
          text: this.$t(`models.contract.attributes.propertyTypeOptions.${propertyType}`)
        }
      })
    },

    readjustmentIndexes() {
      return ['igpm', 'inpc', 'ipca']
    },

    readjustmentIndexOptions() {
      return this.readjustmentIndexes.map(readjustmentIndex => {
        return {
          id: readjustmentIndex,
          text: this.$t(`models.contract.readjustmentIndex.${readjustmentIndex}`)
        }
      })
    },

    paymentTypes() {
      return ['expired_month', 'month_to_expire']
    },

    paymentTypeOptions() {
      return this.paymentTypes.map(paymentType => {
        return {
          id: paymentType,
          text: this.$t(`models.contract.paymentType.${paymentType}`)
        }
      })
    },

    closedMonthTypeOptions() {
      return this.closedMonthTypes.map(closedMonthType => {
        return {
          id: closedMonthType,
          text: this.$t(`models.contract.attributes.closedMonthOptions.${closedMonthType}`)
        }
      })
    },


    statuses() {
      return [
        'active', 'inactive', 'to_be_vacant', 'eviction_process',
        'paused', 'under_verification'
      ]
    },

    statusOptions() {
      return this.statuses.map(status => {
        return {
          id: status,
          text: this.$t(`models.contract.status.${status}`)
        }
      })
    },

    getContractParam() {
      return this.$route.params.id
    },

    contractPath() {
      return `/admins/contracts/${this.getContractParam}`
    },

    propertyPath() {
      return `/admins/contracts/${this.getContractParam}/property`
    },

    tenantPath() {
      return `/admins/contracts/${this.getContractParam}/tenant`
    },

    landlordPath() {
      return `/admins/contracts/${this.getContractParam}/landlord`
    },

    translateDropDownUrls() {
      let dropDownItems = []

      this.contract.filesUrls.forEach(file => {
       if (!file) return

        dropDownItems.push({
          name: this.$t(`.actions.dropDown.items.${strings.camelize(file.fileName)}`),
          address: file.address
        })
      })

      return dropDownItems
    }
  },

  methods: {
    propertyTypeTranslate(type) {
      if (type) {
        return this.$t(
          `models.contract.attributes.propertyTypeOptions.${type}`
        )
      }

      return '-'
    },

    editPropertyNumber() {
      this.$refs.propertyNumberField.editingDisabled = false
      this.$refs.propertyNumberField.$el.querySelector('input').focus();
    },

    resetAddressValues() {
      this.address = {
        state: null,
        neighborhood: null,
        city: null,
        street: null,
        zipCode: null
      }

      this.editZipCode = false
      this.waitingZipCodeInput = true
    },

    getAddress(value) {
      let zipCodeValue = value.target.value.replace('-', '')

      if (zipCodeValue.length < 8) return this.waitingZipCodeInput = true

      this.submitting = true

      cep(zipCodeValue)
        .then((result) => {
          this.address = {
            state: result.state,
            neighborhood: result.neighborhood,
            city: result.city,
            street: result.street,
            zipCode: value.target.value
          }

          this.waitingZipCodeInput = false
        })
        .finally(() => {
          this.submitting = false
        })
    },

    submitAddress() {
      this.submitting = true

      const updateParams = { property: objects.snakeize(this.address) }

      this.$http
        .put(this.propertyPath, updateParams)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.editZipCode = false
          this.submitting = false
        })
    },

    showConfirmationModal() {
      this.showModal = true
    },

    onUpdate() {
      this.$emit("updateContract")
    },

    removeContract() {
      this.loadingModal = true

      this.$http
        .delete(`/real_estate/admins/contracts/${this.contract.id}`)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.removeContract.failure"))
          this.showDiscardErrorModal = true
        })
        .finally(() => {
          this.showModal = false
          this.loadingModal = false
        })
    }
  },
}
</script>

<style lang="scss">
.contract-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
.loading-content {
  background: $loading-gradient;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  width: 100%;
  height: 3.5rem;
  margin-bottom: 1.5rem;

  &.observation {
    height: 15rem;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.info-title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $second-color-light;
}

.info-content {
  font-size: 1.5rem;
  font-family: Lato;

  &.clickable {
    &:hover {
      background-color: darken($white-color, 5%);
    }
  }
}

.inline-section {
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  .one-column {
    width: 10%;
  }

  .two-columns {
    width: 47%;
  }

  .three-columns {
    width: 30%;
  }

  .four-columns {
    width: 52%;
  }

  .eleven-columns {
    width: 90%;
  }

  .full-size {
    width: 100%;
  }

  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    input {
      width: 100%;
      padding: 0px 10px;
      font-family: Lato;
      font-size: 1.5rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }

      .button-progress {
        background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
        background-size: 200%;
        animation: progress 20s ease forwards;
      }

      @keyframes progress {
        from { background-position: right; }
        to { background-position: left; }
      }
    }
  }
}

.buttons-container {
  display: flex;
  gap: 1.5rem;

  button {
    text-align: center;
    color: $second-color-light;
    font-weight: 400;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 1.5rem;
    height: auto;
    width: fit-content;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: none;

    &.destroy-contract {
      color: $red-color;
      background-color: lighten($red-color, 54%);

      &:hover {
        background-color: lighten($red-color, 49%);
      }
    }
  }
}
</style>