<template lang="pug">
.toggleable-input-container
  select-field.three-columns(
    :label="label",
    :name="name",
    :value="0",
    :options="toggleOptions",
    @update:modelValue="toggle"
  )
  .toggle-inputs(v-if="actived == 1")
    slot
</template>
<script>
  export default {
    props: {
      label: { type: String, required: true },
      name: { type: String, required: true }
    },

    data() {
      return {
        toggleOptions: [
          { id: 0, text: this.$t('realEstate.toggleableInputs.no') },
          { id: 1, text: this.$t('realEstate.toggleableInputs.yes') }
        ],

        actived: false
      }
    },

    methods: {
      toggle(e) {
        this.actived = e
      }
    },
  }
</script>
<style lang="scss" scoped>
  .toggleable-input-container {
    width: 100%;

    .three-columns {
      width: 30%;
    }
  }
</style>
